<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('initialimpact', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mt-2">
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Habitat / habitat simplifié'" ressource="habitats" :listObject.sync="autoComplete.ini_hab_id"
                    :listParent.sync="autoCompleteSource.hab" :fields="{ index: 'hab_id', text:'hab_name', search:'hab_name', multiSelect:false }"
                    class="ml-2 mr-2" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="typeItem" v-model="objItem.ini_typ_id" label="Enjeux écologiques"
                    item-text="typ_name" item-value="typ_id" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0 && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('initialimpact', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'edit_initial_impact',
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    data: function () {
        return {
            cardTitle: 'Impact initial',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            validForm   : true,
            loading: { getItem: true },
            typeItem: cacheGetters.getTypes('CMP'),
            objItem: { 
                ini_id: this.itemId, ini_trs_id: this.objTrackingSheet.trs_id, ini_hab_id: null, ini_typ_id: 'CMP01',
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            autoComplete: {
                ini_hab_id: null
            },
            autoCompleteSource:{
                hab: []
            },
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/initialimpact/' + this.itemId + '/?include=habitat' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    if( this.objItem.habitat ){
                        this.autoCompleteSource.hab.push(this.objItem.habitat)
                        this.autoComplete.ini_hab_id = this.objItem.ini_hab_id
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            this.objItem.ini_hab_id = this.autoComplete.ini_hab_id || null
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/initialimpact/' + this.itemId, this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('initialimpact', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('initialimpact', 0, 1)
                })
            } else {
                this.$http.post( '/initialimpact', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('initialimpact', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('initialimpact', 0, 0)
                });
            }
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/initialimpact/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.switchModal('initialimpact', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.switchModal('initialimpact', 0, 1)
                })
            }
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>