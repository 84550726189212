<template>
<v-card color="grey lighten-4" flat>
    <!-- <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('modalAdvice', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar> -->
    <v-card-text class="mt-2">
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
                <v-flex xs12 class="pa-2">
                    <v-select :items="getCacheType('ADT')" 
                        item-text="typ_name" item-value="typ_id"
                        v-model="objItem.adv_typ_id_advice" label="Type d'avis" single-line style="z-index:1500">
                    </v-select>
                </v-flex>
                <v-flex xs6 class="pa-2">
                    <v-checkbox :v-model="objItem.adv_object" :false-value="0" :true-value="1" label="Sans Objet" ></v-checkbox>
                </v-flex>
                <v-flex xs6 class="pa-2">
                    <DatePicker :label="'Avis sollicité le'" :objectForm="objItem" fieldName='adv_date'></DatePicker>
                </v-flex>
                <v-flex xs6 class="pa-2">
                    <AutoCompMulti v-if="!loading.getItem" :title="'Auteur'" ressource="contacts" :listObject.sync="objItem.adv_ctt_id"
                        :listParent.sync="autoCompleteSource.ctt" 
                        :fields="{ index: 'ctt_id', text:'ctt_middlename', search:'ctt_middlename', multiSelect:false, prefetch:false  }"
                        :disabled="!$isAuthorized( 'Advice', 'update' )" /> 
                </v-flex>
                <v-flex xs6 class="pa-2"></v-flex>
                <v-flex xs6 class="pa-2">
                    <v-select :items="getCacheType('ADV')" 
                        item-text="typ_name" item-value="typ_id"
                        v-model="objItem.adv_typ_id" label="Avis sur suite à donner" single-line style="z-index:1500">
                    </v-select>
                    <v-textarea rows="1" full-width name="input-7-4" label="Commentaires" v-model="objItem.adv_custom_fields.adv_comment"></v-textarea>
                </v-flex>
                <v-flex xs6 class="pa-2">
                    <DatePicker :label="'Date'" :objectForm="objItem" fieldName='adv_date_advice'></DatePicker>
                </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0 && isAuthorized( 'Advice', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem && isAuthorized( 'Advice', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="(!objItem.adv_date)">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('modalAdvice', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import AutoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"
import DatePicker from '@/components/ui/DatePicker'

export default {
    name: 'edit_zone',
    components: {
        AutoCompMulti, DatePicker
    },
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            cardTitle: 'Avis',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            validForm   : true,
            typeItem: cacheGetters.getTypes('ZOL'),
            objItem: { 
                adv_id: 0, adv_trs_id: this.objTrackingSheet.trs_id, adv_sta_id: 'GEN01', adv_typ_id: 'ADV01', adv_typ_id_advice: 'ADT01', adv_ctt_id: null,
                adv_ctt_id_company: null, adv_object: 0, adv_date: null, adv_date_advice: null, adv_date_agree: '', adv_custom_fields: {}
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            autoComplete: {
                adv_ctt_id: null,
                adv_ctt_id_company: null,
            },
            autoCompleteSource:{
                ctt: [],
                ctc: [],
                trs: [],
            },
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/advice/' + this.itemId + '/?include=author' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    this.objItem.adv_custom_fields = (!this.objItem.adv_custom_fields) ? {} : this.objItem.adv_custom_fields
                    if( this.objItem.author ){
                        this.autoCompleteSource.ctt.push(this.objItem.author)
                        //this.autoComplete.adv_ctt_id = this.objItem.adv_ctt_id
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/advice/' + this.itemId, this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.saveProgress = false
                    this.switchModal('modalAdvice', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('modalAdvice', 0, 1)
                })
            } else {
                this.$http.post( '/advice', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.saveProgress = false
                    this.switchModal('modalAdvice', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.saveProgress = false
                    this.switchModal('modalAdvice', 0, 0)
                });
            }
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/advice/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.saveProgress = false
                    this.switchModal('modalAdvice', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.saveProgress = false
                    this.switchModal('modalAdvice', 0, 1)
                })
            }
        },
        getCacheType   : cacheGetters.getTypes,
    }
}
</script>