<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('trackingzone', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mt-2">
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs12 class="pa-2">
                <v-text-field label="Nom de la zone" v-model="objItem.tzo_name" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="typeItem" v-model="objItem.tzo_typ_id" label="Type de zone"
                    item-text="typ_name" item-value="typ_id" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="[1, 2, 3]" v-model="objItem.tzo_type" label="Type" :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Ordre" v-model="objItem.tzo_num" required :disabled="!isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0 && isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem && isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('trackingzone', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
//import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"

export default {
    name: 'edit_zone',
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    data: function () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            cardTitle: 'Zonage',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            validForm   : true,
            typeItem: cacheGetters.getTypes('ZOL'),
            objItem: { 
                tzo_id: this.itemId, tzo_trs_id: this.objTrackingSheet.trs_id, tzo_num: 0, tzo_typ_id: 'ZOL01', tzo_name: '', tzo_type: 1,
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/trackingzone/' + this.itemId ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/trackingzone/' + this.itemId, this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('trackingzone', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('trackingzone', 0, 1)
                })
            } else {
                this.$http.post( '/trackingzone', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('trackingzone', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('trackingzone', 0, 0)
                });
            }
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/trackingzone/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.switchModal('trackingzone', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.switchModal('trackingzone', 0, 1)
                })
            }
        }
    },
    components: {
        //'auto-multi': autoCompMulti
    }
}
</script>