<template>
<v-container fluid class="pa-0 ma-0">
<v-card style="z-index:1100;">
<v-layout row wrap>
    <v-flex xs12 class="pa-2">
        <v-list class="pb-0">
            <v-list-item 
                v-for="item in items" :key="item.trs_id" 
                class="pb-0" @click="menuState(false)" :href="'/#/trackingsheet/site/' + item.trs_id ">
                <v-list-item-avatar>
                    <v-icon large :color="(item.trs_id == openedSheetId) ? 'green' : 'grey'">mdi-file-eye</v-icon>
                </v-list-item-avatar>
                <v-list-item-content style="border-bottom: 1px solid #CFD8DC">
                    <v-list-item-title :style="'color:' + item.sta_color + ';'">
                        {{ item.trs_coddt || item.trs_mc2_code }} <span class="caption grey--text">({{ item.trs_area_plot }} h)</span>
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip small outlined label light :color="state['SIT'][item.trs_typ_id].typ_color">
                        {{ state['SIT'][item.trs_typ_id].typ_name }}
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-list class="pb-0" v-if="!itemsCount">
            <v-list-item>
                <v-list-item-content style="border-bottom: 1px solid #CFD8DC">
                    <v-list-item-title style="color:orange">
                        Aucun site en favoris
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-flex>
</v-layout>
</v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheData } from "../store/cache.module"

export default {
    name: 'view-open',
    props: ['filterDiff', 'menuState', 'tabsheetCount'],
    data: function () {
        return {
            saveProgress: false,
            locDateFormat: dateLocale, // Format de date
            state : cacheGetters.getTypes(),
            menu: false,
            //items: {}
        }
    },
    computed: {
        openedSheetId(){
            if( this.$route.params.trs_id !== undefined && this.$route.params.trs_id > 0 ){
                return this.$route.params.trs_id
            } else {
                return false
            }
        },
        items() {
            return cacheData.bookmark
        },
        itemsCount(){
            var count = 0
            for(var prop in cacheData.bookmark) {
                if( prop.length > 0 ){
                    ++count
                }
            }
            return count
        }
    },
    methods: {

    },
    components: { 

    }
}
</script>