<template>
<v-container fluid class="pa-3">
    <v-form ref="siteform" v-model="validFrom" lazy-validation :disabled="routeState.view">
        <v-layout wrap>
        <v-flex xs12 class="pl-2 pr-2 pb-1">
            <v-expansion-panels v-model="advicePanel">
                <v-expansion-panel :expand="true">
                    <v-expansion-panel-header disable-icon-rotate>
                        <template v-slot:actions>
                            <v-icon color="primary">$vuetify.icons.expand</v-icon>
                        </template>
                        <template v-slot:default>
                            <div><span class="font-weight-bold">Avis</span></div>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-layout row wrap>
                            <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Liste des avis</code>
                            <v-flex xs12 class="pa-2 pr-1 pl-1">
                            <v-data-table :headers="viewsHeaders" :items="items" class="elevation-1 tab-zindex" :search="searchViews">
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title v-show="true">
                                            <v-alert class="mb-0" border="left" dense colored-border type="info">Avis</v-alert>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    <v-btn v-if="isAuthorized( 'Advice', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openAdvice(0)" :disabled="!routeState.edit">
                                        Ajouter<v-icon>mdi-playlist-edit</v-icon>
                                    </v-btn>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.adv_date_advice`]="{ item }">
                                    {{ (item.adv_date_advice) ? $date(item.adv_date_advice).format('DD/MM/YYYY') :  '' }}
                                </template>
                                <template v-slot:[`item.adv_typ_id_advice`]="{ item }">
                                    <v-chip label outlined>{{ typeState[item.adv_typ_id_advice.substring(0, 3)][item.adv_typ_id_advice].typ_name }}</v-chip>
                                </template>

                                <template v-slot:[`item.adv_typ_id`]="{ item }">
                                    <v-chip label outlined>{{ typeState[item.adv_typ_id.substring(0, 3)][item.adv_typ_id].typ_name }}</v-chip>
                                </template>
                                <template v-slot:[`item.adv_id`]="{ item }">
                                    <v-chip v-if="isAuthorized( 'Advice', 'update' )" @click="openAdvice(item)" color="grey" label outlined><v-icon>mdi-pencil</v-icon></v-chip>
                                    <v-chip v-else @click="openEvent(item)" color="grey" label outlined><v-icon>mdi-eye</v-icon></v-chip>
                                </template>
                            </v-data-table>
                            </v-flex>
                        </v-layout>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-flex>
        <v-flex xs12 class="pl-2 pr-2 pb-1">
            <v-expansion-panels >
                <v-expansion-panel >
                    <v-expansion-panel-header>
                    <template v-slot:actions>
                        <!-- <v-chip small outlined color="secondary">
                            <v-icon color="primary">mdi-bookmark-plus-outline</v-icon>
                            {{ locDateFormat.toLocale(objTrackingSheet.trs_created_at) }}
                        </v-chip>
                        <v-chip small outlined color="green">
                            <v-icon color="green">mdi-pencil-outline</v-icon>{{ locDateFormat.toLocale(objTrackingSheet.trs_updated_at) }}
                        </v-chip> -->
                        <v-icon color="primary">$vuetify.icons.expand</v-icon>
                    </template>
                    <template v-slot:default>
                        <div><span class="font-weight-bold">Avis (archives)</span></div>
                    </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    <v-layout row wrap>
                        <!-- Avis COSEA -->
                        <code class="pa-2 mb-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Avis COSEA</code>
                        <v-flex xs6 class="pa-2">
                            <AutoCompMulti :title="'Auteur'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC08"></AutoCompMulti>
                        </v-flex>
                        <v-flex xs6 class="pa-2"></v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-select :items="[{text: 'Non défini', value:0 }, {text: 'Favorable', value:1 }, {text: 'Défavorable', value:2}, {text: 'Non défini', value:3 }]" 
                                item-text="text" item-value="value"
                                v-model="objTrackingSheet._data['trackingstates']['TST40'].tst_state_numeric" label="Avis sur suite à donner" single-line style="z-index:1500">
                            </v-select>
                            <v-textarea :readonly="routeState.view" dense rows="1" full-width name="input-7-4" label="Commentaires" v-model="objTrackingSheet._data['trackingstates']['TST40'].tst_note.cosea_commentaires"></v-textarea>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                        <v-menu ref="dtopinion" v-model="objTrackingSheet._menu['TST40']" :close-on-content-click="false" :nudge-right="40" :return-value.sync="objTrackingSheet._data['trackingstates']['TST40'].tst_date"
                            transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                    v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST40'].tst_date, 'fr' )"
                                    label="Date" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                            </template>
                            <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST40'].tst_date">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="objTrackingSheet._menu['TST40'] = false">Annuler</v-btn>
                                <v-btn color="primary" @click="$refs.dtopinion.save(objTrackingSheet._data['trackingstates']['TST40'].tst_date)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-textarea :readonly="routeState.view" dense rows="1" full-width name="input-7-4" label="Reserves" v-model="objTrackingSheet._data['trackingstates']['TST41'].tst_note.cosea_reserve_comm"></v-textarea>
                        </v-flex>
                    </v-layout>
                    <!-- Avis APN, CEN ou Fédération de pêche -->
                    <v-layout row wrap>
                        <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Avis APN, CEN ou Fédération de pêche</code>
                        <v-flex xs6 class="pa-2">
                            <AutoCompMulti :title="'Organisme'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC12"></AutoCompMulti>
                            <AutoCompMulti :title="'Auteur'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC13"></AutoCompMulti>
                        </v-flex>
                        <v-flex xs6 class="pa-2"></v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-select :items="[{text: 'Non défini', value:0 }, {text: 'Favorable', value:1 }, {text: 'Défavorable', value:2}, {text: 'Non défini', value:3 }]" 
                                item-text="text" item-value="value"
                                v-model="objTrackingSheet._data['trackingstates']['TST42'].tst_state_numeric" label="Avis sur suite à donner" single-line style="z-index:1500">
                            </v-select>
                            <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Commentaires" v-model="objTrackingSheet._data['trackingstates']['TST42'].tst_note.cren_commentaires"></v-textarea>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                        <v-menu ref="dtopinionapn" v-model="objTrackingSheet._menu['TST42']" :close-on-content-click="false" :nudge-right="40" :return-value.sync="objTrackingSheet._data['trackingstates']['TST42'].tst_date"
                            transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                    v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST42'].tst_date, 'fr' )"
                                    label="Date" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                            </template>
                            <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST42'].tst_date">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="objTrackingSheet._menu['TST42'] = false">Annuler</v-btn>
                                <v-btn color="primary" @click="$refs.dtopinionapn.save(objTrackingSheet._data['trackingstates']['TST42'].tst_date)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Reserves" v-model="objTrackingSheet._data['trackingstates']['TST43'].tst_note.cren_reserve_comm"></v-textarea>
                        </v-flex>
                    </v-layout>
                    <!-- Avis préalable ou éligibilité -->
                    <v-layout row wrap>
                        <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Avis préalable ou éligibilité du site par les services de l'Etat</code>
                        <code class="pa-0 caption font-italic theme--dark v-toolbar" style="color:black; width:100%;">Avis DREAL</code>
                        <v-flex xs6 class="pa-2">
                            <v-checkbox :v-model="true" label="Sans Objet" ></v-checkbox>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopiniondreal" v-model="objTrackingSheet._menu['TST46']" :close-on-content-click="false" :nudge-right="40" :return-value.sync="objTrackingSheet._data['trackingstates']['TST46'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST46'].tst_date, 'fr' )"
                                        label="Avis sollicité le" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST46'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST46'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopiniondreal.save(objTrackingSheet._data['trackingstates']['TST46'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <AutoCompMulti :title="'Auteur'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC15"></AutoCompMulti>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopiniondrealeli" v-model="objTrackingSheet._menu['TST49']" :close-on-content-click="false" :nudge-right="40" :return-value.sync="objTrackingSheet._data['trackingstates']['TST49'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST49'].tst_date, 'fr' )"
                                        label="Eligibilité accordée par la DREAL" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST49'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST49'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopiniondrealeli.save(objTrackingSheet._data['trackingstates']['TST49'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-select :items="[{text: 'Non défini', value:0 }, {text: 'Favorable', value:1 }, {text: 'Défavorable', value:2}, {text: 'Non défini', value:3 }]" 
                                item-text="text" item-value="value"
                                v-model="objTrackingSheet._data['trackingstates']['TST47'].tst_state_numeric" label="Avis sur suite à donner" single-line style="z-index:1500">
                            </v-select>
                            <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Commentaires" v-model="objTrackingSheet._data['trackingstates']['TST44'].tst_note.cst_commentaires"></v-textarea>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopiniondrealnext" v-model="objTrackingSheet._menu['TST47']" :close-on-content-click="false" :nudge-right="40" :return-value.sync="objTrackingSheet._data['trackingstates']['TST47'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST47'].tst_date, 'fr' )"
                                        label="Date" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST47'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST47'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopiniondrealnext.save(objTrackingSheet._data['trackingstates']['TST47'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Reserves" v-model="objTrackingSheet._data['trackingstates']['TST45'].tst_note.cst_reserve_comm"></v-textarea>
                        </v-flex>
                        <!-- Avis DDT -->
                        <code class="pa-0 caption font-italic theme--dark v-toolbar" style="color:black; width:100%;">Avis DDT</code>
                        <v-flex xs6 class="pa-2">
                            <v-checkbox :v-model="true" label="Sans Objet" ></v-checkbox>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopinionddt" v-model="objTrackingSheet._menu['TST51']" :close-on-content-click="false" :nudge-right="40" 
                                :return-value.sync="objTrackingSheet._data['trackingstates']['TST51'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST51'].tst_date, 'fr' )"
                                        label="Avis sollicité le" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST51'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST51'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopinionddt.save(objTrackingSheet._data['trackingstates']['TST51'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <AutoCompMulti :title="'Auteur'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC17"></AutoCompMulti>
                        </v-flex>
                        <v-flex xs6 class="pa-2"></v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-select :items="[{text: 'Non défini', value:0 }, {text: 'Favorable', value:1 }, {text: 'Défavorable', value:2}, {text: 'Non défini', value:3 }]" 
                                item-text="text" item-value="value"
                                v-model="objTrackingSheet._data['trackingstates']['TST52'].tst_state_numeric" label="Avis sur suite à donner" single-line style="z-index:1500">
                            </v-select>
                            <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Commentaires" v-model="objTrackingSheet._data['trackingstates']['TST52'].tst_note.ddt_commentaires"></v-textarea>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopinionddtnext" v-model="objTrackingSheet._menu['TST54']" :close-on-content-click="false" :nudge-right="40" 
                                :return-value.sync="objTrackingSheet._data['trackingstates']['TST54'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST54'].tst_date, 'fr' )"
                                        label="Date" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST54'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST54'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopinionddtnext.save(objTrackingSheet._data['trackingstates']['TST54'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Reserves" v-model="objTrackingSheet._data['trackingstates']['TST53'].tst_note.ddt_reserve_comm"></v-textarea>
                        </v-flex>
                    </v-layout>
                    <!-- Avis sur le plan de gestion des services de l'Etat -->
                    <v-layout row wrap>
                        <code class="pa-2 caption font-italic theme--dark primary v-toolbar" style="color:white; width:100%;">Avis sur le plan de gestion des services de l'Etat</code>
                        <code class="pa-0 caption font-italic theme--dark v-toolbar" style="color:black; width:100%;">Avis DREAL</code>
                        <v-flex xs6 class="pa-2">
                            <v-checkbox :v-model="true" label="Sans Objet" ></v-checkbox>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopinionplandreal" v-model="objTrackingSheet._menu['TST55']" :close-on-content-click="false" :nudge-right="40" 
                                :return-value.sync="objTrackingSheet._data['trackingstates']['TST55'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST55'].tst_date, 'fr' )"
                                        label="Avis sollicité le" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST55'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST55'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopinionplandreal.save(objTrackingSheet._data['trackingstates']['TST55'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <AutoCompMulti :title="'Auteur'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC18"></AutoCompMulti>
                        </v-flex>
                        <v-flex xs6 class="pa-2"></v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-select :items="[{text: 'Non défini', value:0 }, {text: 'Favorable', value:1 }, {text: 'Défavorable', value:2}, {text: 'Non défini', value:3 }]" 
                                item-text="text" item-value="value"
                                v-model="objTrackingSheet._data['trackingstates']['TST56'].tst_state_numeric" label="Avis sur suite à donner" single-line style="z-index:1500">
                            </v-select>
                            <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Commentaires" v-model="objTrackingSheet._data['trackingstates']['TST56'].tst_note.dreal_commentaires"></v-textarea>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopinionsoldreal" v-model="objTrackingSheet._menu['TST56']" :close-on-content-click="false" :nudge-right="40" 
                                :return-value.sync="objTrackingSheet._data['trackingstates']['TST56'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST56'].tst_date, 'fr' )"
                                        label="Date" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST56'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST56'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopinionsoldreal.save(objTrackingSheet._data['trackingstates']['TST56'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                        <!-- Avis DDT -->
                        <code class="pa-0 caption font-italic theme--dark v-toolbar" style="color:black; width:100%;">Avis DDT</code>
                        <v-flex xs6 class="pa-2">
                            <v-checkbox :v-model="true" label="Sans Objet" ></v-checkbox>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopinionsolddt" v-model="objTrackingSheet._menu['TST57']" :close-on-content-click="false" :nudge-right="40" 
                                :return-value.sync="objTrackingSheet._data['trackingstates']['TST57'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST57'].tst_date, 'fr' )"
                                        label="Avis sollicité le" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST57'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST57'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopinionsolddt.save(objTrackingSheet._data['trackingstates']['TST57'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <AutoCompMulti :title="'Auteur'" :objTrackingSheet.sync="objTrackingSheet" ctt_typ="TSC19"></AutoCompMulti>
                        </v-flex>
                        <v-flex xs6 class="pa-2"></v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-select :items="[{text: 'Non défini', value:0 }, {text: 'Favorable', value:1 }, {text: 'Défavorable', value:2}, {text: 'Non défini', value:3 }]" 
                                item-text="text" item-value="value"
                                v-model="objTrackingSheet._data['trackingstates']['TST58'].tst_state_numeric" label="Avis sur suite à donner" single-line style="z-index:1500">
                            </v-select>
                            <v-textarea :readonly="routeState.view" rows="1" full-width name="input-7-4" label="Commentaires" v-model="objTrackingSheet._data['trackingstates']['TST58'].tst_note.plan_ddt_commentaires"></v-textarea>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-menu ref="dtopinionplanddt" v-model="objTrackingSheet._menu['TST58']" :close-on-content-click="false" :nudge-right="40" 
                                :return-value.sync="objTrackingSheet._data['trackingstates']['TST58'].tst_date"
                                transition="scale-transition" offset-y min-width="290px" style="z-index:1500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field 
                                        v-bind="attrs" :value="locDateFormat.formatLocale( objTrackingSheet._data['trackingstates']['TST58'].tst_date, 'fr' )"
                                        label="Date" prepend-icon="mdi-calendar-today" readonly v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker locale="fr-fr" no-title scrollable v-model="objTrackingSheet._data['trackingstates']['TST58'].tst_date">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="objTrackingSheet._menu['TST58'] = false">Annuler</v-btn>
                                    <v-btn color="primary" @click="$refs.dtopinionplanddt.save(objTrackingSheet._data['trackingstates']['TST58'].tst_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-flex>
        </v-layout>
        <v-divider class="mt-5"></v-divider>
    </v-form>
    <v-dialog v-model="modalAdvice" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Avis</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalAdvice', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pa-2">
            <EditAdvice v-if="modalAdvice" class="elevation-0" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="adv_id" />
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import AutoCompMulti    from './Autocomplete_contact.vue'
import dateLocale       from '../services/dateLocale';
import { usrGetters }   from "../store/user.module"
import EditAdvice  from '../components/Edit_advice.vue'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'site_edit',
    components: {
        EditAdvice,
        AutoCompMulti
    },
    props: ['objTrackingSheet', 'routeState', 'sitComponent', 'reloadState'], 
    data: function () {
        return {
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            locDateFormat: dateLocale, // Format de date
            panel : 1,
            loading: { getVersion: false },
            validFrom: true,
            saveProgress: false,         // Enregistrement en cours...
            dateSit: { start: false, end: false },
            formRules: {
                name: [
                    v => !!v || 'Le nom doit être renseigné',
                    v => (v && v.length <= 60) || 'Le nom doit être inférrieur à 60 characters'
                ]
            },
            addSource: [{text:'-', value:''}],
            selSource: 0,
            radios: { eau: '' },
            viewObj: {},
            searchViews: '',
            viewsHeaders: [
                { text: 'Type',     value:'adv_typ_id_advice' },
                { text: 'Date avis',  value:'adv_date_advice' },
                { text: 'Auteur',   value:'ctt_middlename' },
                { text: 'Statut',   value:'adv_typ_id' },
                { text: 'Action',  value:'adv_id', sortable: false },
            ],
            items       : [],
            advicePanel : [0],
            modalAdvice : false,
            adv_id : 0,
            isAuthorized: usrGetters.getScopeRight
        }
    },
    mounted:function (){
       this.getAdvice()
    },
    methods: {
        getAdvice(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.$http.get( '/advice/?per_page=false&extended=withauthor&adv_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.items.push(...response.data.data)
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        openAdvice(item){
            this.adv_id = item.adv_id
            this.switchModal('modalAdvice', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalAdvice', 1)
            }
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalAdvice'
            mode    = mode || 0
            refresh = refresh || true
            this[name] = mode
            if( mode == 0 ){
                this.adv_id = 0
            }
            if( refresh ){ 
                this.getAdvice()
            }
        },
    }
}
</script>