<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('compensationwater', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mt-2">
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Bassins versant'" ressource="watershed" :listObject.sync="autoComplete.cwa_was_id"
                    :listParent.sync="autoCompleteSource.was" :fields="{ index: 'was_id', text:'was_name', search:'was_name', multiSelect:false, preprendIcon:'mdi-image-filter-hdr' }"
                    class="ml-2 mr-2" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Masse d\'eau'" ressource="watermass" :listObject.sync="autoComplete.cwa_wam_id"
                    :listParent.sync="autoCompleteSource.wam" :fields="{ resultlimit:60, params: 'order_by=wam_name&order_direction=asc', index: 'wam_id', text:'wam_name', search:'wam_name', multiSelect:false, preprendIcon:'mdi-waves' }"
                    class="ml-2 mr-2" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="typeItem" v-model="objItem.cwa_typ_id" label="Type de compensation"
                    item-text="typ_name" item-value="typ_id" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Estimation en pré-dignostic" type="numeric" 
                    v-model="objItem.cwa_value_pre_diag" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs2 class="pa-2">
                <v-select 
                    :items="[{ id:0, name: 'Linéaire' }, { id:1, name: 'Surface' }]" v-model="objItem.cwa_linear_state" label="Unité"
                    item-text="name" item-value="id" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs5 class="pa-2">
                <v-text-field label="Linéaire soumis à validation (ml)" 
                    v-model="objItem.cwa_value_det_diag" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs5 class="pa-2">
                <v-text-field label="Linéraire de compensation validé (ml)" 
                    v-model="objItem.cwa_value_validated" required :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0 && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('compensationwater', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'edit_compensation_water',
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    data: function () {
        return {
            cardTitle: 'Compensation loi sur l\'eau',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            validForm   : true,
            typeItem: cacheGetters.getTypes('CMP'),
            objItem: { 
                cwa_id: this.itemId, cwa_trs_id: this.objTrackingSheet.trs_id, cwa_typ_id: '', 
                cwa_was_id: '', cwa_wam_id: '', cwa_linear_state: 0,
                cwa_value_pre_diag: '', cwa_value_det_diag: '', cwa_value_validated: '',
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            autoComplete: {
                cwa_was_id: null, cwa_wam_id: null,
            },
            autoCompleteSource:{
                was: [], wam: [],
            },
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/compensationwater/' + this.itemId + '/?include=watermass,watershed' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    if( this.objItem.watershed ){
                        this.autoCompleteSource.was.push(this.objItem.watershed)
                        this.autoComplete.cwa_was_id = this.objItem.cwa_was_id
                    }
                    if( this.objItem.watermass ){
                        this.autoCompleteSource.wam.push(this.objItem.watermass)
                        this.autoComplete.cwa_wam_id = this.objItem.cwa_wam_id
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            this.objItem.cwa_was_id = this.autoComplete.cwa_was_id || null
            this.objItem.cwa_wam_id = this.autoComplete.cwa_wam_id || null
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/compensationwater/' + this.itemId, this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationwater', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('compensationwater', 0, 1)
                })
            } else {
                this.$http.post( '/compensationwater', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationwater', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('compensationwater', 0, 0)
                });
            }
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/compensationwater/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.switchModal('compensationwater', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.switchModal('compensationwater', 0, 1)
                })
            }
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>