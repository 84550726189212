<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('compensationhabitat', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mt-2">
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs12 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Habitat ZH'" ressource="habitats" :listObject.sync="autoComplete.cha_hab_id"
                    :listParent.sync="autoCompleteSource.hab" :fields="{ index: 'hab_id', text:'hab_name', search:'hab_name', multiSelect:false }"
                    class="ml-2 mr-2" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </auto-multi>
            </v-flex>
            <v-flex xs4 class="pa-2">
                <v-text-field label="Estimation en pré-dignostic" type="numeric" v-model="objItem.cha_value_pre_diag" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs4 class="pa-2">
                <v-text-field label="Surface soumis à validation (ml)" v-model="objItem.cha_value_det_diag" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs4 class="pa-2">
                <v-text-field label="Surface de compensation validé (ml)" v-model="objItem.cha_value_validated" required :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0 && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('compensationhabitat', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
//import { cacheGetters } from "../store/cache.module"

export default {
    name: 'edit_compensation_habitat',
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    data: function () {
        return {
            cardTitle: 'Compensation loi sur l\'eau',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            validForm   : true,
            loading: { getItem: true },
            objItem: { 
                cha_id: this.itemId, cha_trs_id: this.objTrackingSheet.trs_id, cha_hab_id: 0,
                cha_value_pre_diag: null, cha_value_det_diag: null, cha_value_validated: null,
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            autoComplete: {
                cha_hab_id: null
            },
            autoCompleteSource:{
                hab: [],
            },
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/compensationhabitat/' + this.itemId + '/?include=habitat' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    if( this.objItem.habitat ){
                        this.autoCompleteSource.hab.push(this.objItem.habitat)
                        this.autoComplete.cha_hab_id = this.objItem.cha_hab_id
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            this.objItem.cha_hab_id = this.autoComplete.cha_hab_id || null
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/compensationhabitat/' + this.itemId, this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationhabitat', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('compensationhabitat', 0, 1)
                })
            } else {
                this.$http.post( '/compensationhabitat', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationhabitat', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('compensationhabitat', 0, 0)
                });
            }
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/compensationhabitat/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.switchModal('compensationhabitat', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.switchModal('compensationhabitat', 0, 1)
                })
            }
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>