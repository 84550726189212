<template>
<v-container fluid class="pa-3">
<v-layout row wrap class="pa-3">
    <!-- Caractéristiques -->
    <code class="pa-2 font-italic theme--dark primary v-toolbar" style="color:white; color:white; width:100%;">Caractéristiques foncières</code>
    <v-flex xs6 class="pt-2 pl-3 pr-3" styl="text-align: right;">
        <v-btn v-if="isAuthorized( 'TrackingSheetLanddata', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="switchModal('trackingplot', 0, 1)" :disabled="!routeState.edit">
            Ajouter<v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
    </v-flex>
    <v-flex xs6 class="pt-2 pl-3 pr-3" style="text-align: right;">
        Surface totale : {{ (Math.round( totalAreaPlot * 100 ) / 100).toFixed(2) }}
    </v-flex>
    <v-flex xs12 class="pa-2">
    <v-data-table :headers="plotsHeaders" :items="objTrackingSheet._data['trackingplot']" :items-per-page="5" class="elevation-1" >
        <template v-slot:[`item.dep_name`]="{ item }">
            {{ item.dep_name + ' (' + item.dep_code + ')' }}
        </template>
        <template v-slot:[`item.trp_typ_id`]="{ item }">
            <v-chip small outlined label :color="getCacheType('PLO', item.trp_typ_id).typ_color">
                {{ getCacheType('PLO', item.trp_typ_id).typ_name }}
            </v-chip>
        </template>
        <template v-slot:[`item.trp_area`]="{ item }">{{ (Math.round( item.trp_area * 100 ) / 100).toFixed(2) }}</template>
        <template v-slot:[`item.trp_id`]="{ item }">
            <v-icon small @click="switchModal('trackingplot', item.trp_id, 1)" class="mr-2" color="grey">mdi-information-outline</v-icon>
        </template>
    </v-data-table>
    </v-flex>
</v-layout>
<v-form ref="siteform" v-model="validForm" lazy-validation :disabled="routeState.view">
<v-layout row wrap class="pa-3">
    <!-- Localisation -->
    <code class="pa-2 font-italic theme--dark primary v-toolbar" style="color:white; color:white; width:100%;">Localisation</code>
    <v-flex xs6 class="pa-2">
        <auto-multi :title="'Commune'" ressource="towns" 
            :listObject.sync="objTrackingSheet.trs_tow_id_link" :listParent.sync="autoCompleteSource.tow" params="order_by=tow_name"
            :fields="{ index: 'tow_id', text:'tow_name,tow_zip', resultlimit:false, search:'tow_name', multiSelect:false, preprendIcon:'mdi-map-search' }">
        </auto-multi>fds
        <auto-multi :title="'Lieu dit / adresse'" ressource="address" 
            :listObject.sync="objTrackingSheet.trs_adr_id" :listParent.sync="autoCompleteSource.adr" 
            :fields="{ index: 'adr_id', text:'adr_address', search:'adr_address', multiSelect:false, preprendIcon:'mdi-map-marker' }">
        </auto-multi>
        <auto-multi :title="'Masse d\'eau'" ressource="watermass" 
            :listObject.sync="objTrackingSheet.trs_wam_id" :listParent.sync="autoCompleteSource.wam" 
            :fields="{ resultlimit:60, params: 'order_by=wam_name&order_direction=asc', index: 'wam_id', text:'wam_name', search:'wam_name', multiSelect:false, preprendIcon:'mdi-water' }">
        </auto-multi>
        <v-text-field prepend-inner-icon="mdi-waves" label="Cours d'eau" v-model="objTrackingSheet._data['trackingstates']['TST17'].tst_note.cours_eau" ></v-text-field>
        <v-text-field prepend-inner-icon="mdi-train" label="Pk le plus proche" v-model="objTrackingSheet.trs_mileage_point"></v-text-field>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-text-field disabled label="Département" v-model="objTrackingSheet.dep_name"></v-text-field>
        <v-text-field disabled label="Petite région agricole" v-model="objTrackingSheet.sar_name"></v-text-field>
        <v-text-field disabled label="Bassin versant" v-model="objTrackingSheet.was_name"></v-text-field>
        <v-text-field label="Distance à la ligne" v-model="objTrackingSheet.trs_rail_distance"></v-text-field>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-text-field label="Surface du site (saisie manuelle)" v-model="objTrackingSheet.trs_area_plot"></v-text-field>
        <!-- <v-text-field label="Surface engagée" v-model="objTrackingSheet.trs_customfields.trs_area_engaged"></v-text-field> -->
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-text-field v-if="$isAuthorized( 'TrackingSheet', 'update' )" label="Surface du site (calcul automatique)" 
            v-model="objTrackingSheet.trs_area_specification" disabled>
        </v-text-field>
        <v-text-field v-if="$isAuthorized( 'TrackingSheet', 'update' )" label="Surface validée par les services de l'Etat (saisie manuelle)" 
            v-model="objTrackingSheet.trs_customfields.trs_area_validated">
        </v-text-field>
    </v-flex>
    <v-layout row wrap class="pa-2">
        <code class="pa-2 font-italic theme--dark primary v-toolbar" style="color:white; color:white; width:100%;">Commentaires</code>
        <v-flex xs12 class="pt-2 pl-3 pr-3" >
            <v-textarea :readonly="routeState.view" rows="2" full-width name="input-7-4" label="Commentaires" v-model="objTrackingSheet._data['trackingstates']['TST24'].tst_note.foncier_commentaires"></v-textarea>
        </v-flex>
    </v-layout>
</v-layout>
</v-form>
<!-- Edition tableau -->
<v-dialog v-model="modalEdit" max-width="900">
    <edit-trackingplot v-if="(modalName == 'trackingplot')" :switchModal="switchModal" :objTrackingSheet="objTrackingSheet" :itemId="itemId" ></edit-trackingplot>
</v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters } from "../store/cache.module"
//import autoCompContact    from './Autocomplete_contact.vue'
import autoCompMulti    from './Autocomplete_multi.vue'
import editTrackingPlot from '../components/Edit_tracking_plot.vue'

import { usrGetters }   from "../store/user.module"

export default {
    name: 'tab_landed',
    props: ['objTrackingSheet', 'routeState', 'objTrackingSheetInfo', 'reloadState'], 
    data: function () {
        return {
            modalEdit: false,
            modalName: false,
            itemId: 0,
            loading: { getVersion: false, getTown: false },
            validForm: true,
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            noticeHeaders: [
                { text: ' ',        type:'text',  width: '34' },
                { text: 'En cours', type:'value', width: '22', active: 1 },
                { text: 'Validé',   type:'value', width: '22', active: 2 },
                { text: 'Rejeté',   type:'value', width: '22', active: 3 },
                { text: '',         type:'date',  width: '8',  active: 2 },
            ],
            noticeProp: [ 'TST08', 'TST09', 'TST10', 'TST11', 'TST12' ], //ToDoo verifier qu'elles existent ou select par typ_tag
            noticeDate: { 'TST08':false, 'TST09':false, 'TST10':false, 'TST11':false, 'TST12':false },
            typeState: cacheGetters.getTypes(),
            plotsHeaders: [
                { text: 'Département', value: 'dep_name', align: 'left', sortable: true },
                { text: 'Communes',    value: 'tow_name', sortable: true },
                { text: 'Section',     value: 'trp_section', sortable: true },
                { text: 'N° parcelle', value: 'trp_plot_id', sortable: true },
                { text: 'Type',        value: 'trp_typ_id', sortable: true },
                { text: 'Surface',     value: 'trp_area' },
                { text: ' ',           value: 'trp_id', sortable: false },
            ],
            plots: [],
            totalAreaPlot: 0,
            autoComplete: {
                trs_tow_id: null, trs_adr_id: null, trs_wam_id: null
            },
            autoCompleteSource:{
                tow: [], adr: [], wam: []
            },
            search: { towns: '', address: '', watershead:'' },
            searchListTow: [], searchListAdr: [], searchListWas:[],
            searchFocus: '',
            addSource: [{text:'-', value:''}],
            selSource: 0,
            switch1: true,
            date_prem_ct: '',
            isAuthorized: usrGetters.getScopeRight,
        }
    },
    computed: { },
    beforeMount:function (){
        this.autoCompleteSource.tow.push(this.objTrackingSheet._info.town)
        this.autoCompleteSource.wam.push(this.objTrackingSheet._info.watermass)
        this.autoCompleteSource.adr.push(this.objTrackingSheet._info.address)
        //this.autoComplete.trs_tow_id = this.objTrackingSheet.trs_tow_id_link
        //this.searchListTow.push(this.objTrackingSheet._info.town)
        if( this.objTrackingSheet.trs_typ_id !== 'SIT02' ){
            this.noticeProp = [ 'TST13', 'TST14', 'TST15' ]
            this.noticeDate = { 'TST13':false, 'TST14':false, 'TST15':false }
        }
    },
    mounted:function (){
        this.getTrackingplot()
    },
    methods: {
        changeState(prop, state){
            this.objTrackingSheet._data['trackingstates'][prop].tst_state_numeric = state
            this.$emit('update:objTrackingSheet', this.objTrackingSheet)
        },
        sumTotalAreaPlot(){
            this.totalAreaPlot = 0
            this.objTrackingSheet._data['trackingplot'].forEach(element => {
                this.totalAreaPlot += parseFloat(element.trp_area)
            })
        },
        getTrackingplot(){
            this.$http.get( '/trackingplot/?extended=true&per_page=false&trp_trs_id=' + this.objTrackingSheet.trs_id ).then( (response) => {
                this.objTrackingSheet._data['trackingplot'] = response.data.data
                this.sumTotalAreaPlot()
            })
        },
        switchModal(name, itemId, mode, refresh){
            name    = name    || this.modalName
            mode    = mode    || 0
            refresh = refresh || false
            this.itemId    = itemId || 0
            this.modalEdit = mode
            this.modalName = name
            if( refresh ){ 
                switch (this.modalName) {
                    case 'trackingplot':
                        this.getTrackingplot()
                        break;
                }
            }
            this.modalName = (mode) ? this.modalName : ''
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        'auto-multi': autoCompMulti,
        //'auto-contact': autoCompContact,
        'edit-trackingplot': editTrackingPlot
    }
}
</script>