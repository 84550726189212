<template>
<v-container fluid class="pt-0 pl-2 pr-2">
    <v-layout wrap>
        <v-card class="mt-0 pt-2 elevation-0" style="width: 100%;" v-if="!loading.getItem">
            <v-toolbar rounded dense class="elevation-1">
                <v-toolbar-title>{{labels.title}}</v-toolbar-title>
                <v-chip small text-color="white" class="ml-2" color="blue-grey lighten-3">{{ items.length }}</v-chip>
                <v-spacer></v-spacer>
                <v-btn outlined rounded dark small color="success" @click="openModal({ spf_id: 0, tcv_id: 0 })" :disabled="!routeState.edit"
                    v-show="( isAuthorized( 'TrackingSheetConvention', 'store' ) )">
                    <v-icon dark :title="labels.newSheet">mdi-plus</v-icon>{{labels.newSheet}}<span class="hidden-sm-and-down"></span>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <template v-for="(itemTcv, index) in items">
                    <v-card :key="index" class="mb-5" color="#F5F5F5">
                    <v-toolbar dense shaped class="elevation-1" @click="( isAuthorized( 'TrackingSheetConvention', 'update' ) ) ? openModal({ spf_id: 0, tcv_id: itemTcv.tcv_id }, 'convention') : false"
                        :style="'border-left: 4px solid ' + getCacheState('GEN', itemTcv.tcv_sta_id).sta_color + '; cursor:pointer;'">
                            <div>
                                <span class="font-weight-medium">{{getCacheType(labels.typId, itemTcv.tcv_typ_id).typ_name}} : {{ itemTcv.tcv_code }}</span><br />
                                <span v-if="isAuthorized( 'TrackingSheetConvention', 'update' )" class="caption">
                                    {{ (objTrackingSheet.trs_typ_id !== 'SIT02') ? 'Rémunération : ' + new Intl.NumberFormat('fr-FR').format(itemTcv.tcv_amount_ht) + ' €' : '' }}
                                </span>
                            </div>
                        <v-spacer></v-spacer>
                        <v-chip label outlined :color="getCacheState('GEN', itemTcv.tcv_sta_id).sta_color" class="mr-2">{{ getCacheState('GEN', itemTcv.tcv_sta_id).sta_label }}</v-chip>
                        <span class="text-caption">
                            <b>Début: </b><i>{{ locDateFormat.toLocale(itemTcv.tcv_date, { year: 'numeric', month: 'long', day: 'numeric' }) }}</i><br />
                            <b>Fin: </b><i>{{ locDateFormat.toLocale(itemTcv.tcv_deadline, { year: 'numeric', month: 'long', day: 'numeric' }) }}</i>
                        </span>
                    </v-toolbar>
                    <v-card-text style="border-left: 4px solid #2196F3;">
                        <v-layout row wrap>
                            <v-flex xs12 class="pa-2">
                                <v-data-table :headers="specificationHeaders" :items="specification[itemTcv.tcv_id]" :items-per-page="-1" hide-default-footer class="ml-3 elevation-1" >
                                    <template v-slot:top class="pa-3">
                                        <v-toolbar flat>
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="isAuthorized( 'TrackingSheetConvention', 'update' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openModal({ spf_id: 0, tcv_id: itemTcv.tcv_id }, 'specification')" :disabled="!routeState.edit">
                                            Ajouter<v-icon>mdi-playlist-edit</v-icon>
                                        </v-btn>
                                        </v-toolbar>
                                    </template>
                                    
                                    <template v-slot:[`item.cdr_code`]="{ item }">
                                        <v-chip label outlined color="blue" :title="item.cdr_description">
                                            {{ (objTrackingSheet.trs_typ_id == 'SIT02') ? item.spf_custom_fields['spf_code'] : item.cdr_code }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:[`item.spf_custom_fields`]="{ item }">
                                        {{item.spf_custom_fields['spf_precision']}}
                                    </template>
                                    <template v-slot:[`item.spf_date_start`]="{ item }">
                                        <v-btn small outlined color="grey" style="cursor:not-allowed;">{{ locDateFormat.toLocale(item.spf_date_start, { year: 'numeric', month: 'long', day: 'numeric' }) }}</v-btn>
                                    </template>
                                    <template v-slot:[`item.spf_date_expiration`]="{ item }">
                                        <v-btn small outlined v-if="!item.spf_date_expiration || item.spf_date_expiration.substring(0,4) == '1970'" color="grey" style="cursor:not-allowed;">
                                            Restauration initiale
                                        </v-btn>
                                        <v-btn small outlined v-else-if="item.spf_expiration_above" :color="item.spf_expiration_color" @click="updateConventionDate(item)">
                                            {{ locDateFormat.toLocale(item.spf_date_expiration, { year: 'numeric', month: 'long', day: 'numeric' }) }}
                                        </v-btn>
                                        <v-btn small outlined v-else :color="item.spf_expiration_color" style="cursor:not-allowed;">
                                            {{ locDateFormat.toLocale(item.spf_date_expiration, { year: 'numeric', month: 'long', day: 'numeric' }) }}
                                        </v-btn>
                                    </template>
                                    <template v-slot:[`item.spf_area`]="{ item }">
                                        {{ (Math.round(item.spf_area * 100 ) / 100).toFixed(2) }}
                                    </template>
                                    <template v-slot:[`item.spf_typ_id`]="{ item }">
                                        <v-icon v-if="isAuthorized( 'TrackingSheetConvention', 'update' )" small title="Voir / Modifier le cahier des charges"
                                            @click="openModal({ spf_id: item.spf_id, tcv_id: itemTcv.tcv_id }, 'specification')" class="mr-2" color="grey">mdi-information-outline
                                        </v-icon>
                                    </template>
                                    <template v-slot:[`item.spf_tcv_id`]="{ item }">
                                        <v-icon v-if="isAuthorized( 'TrackingSheetConvention', 'index' )" small title="Afficher la liste des interventions"
                                            @click="openModal({ spf_id: item.spf_id, tcv_id: itemTcv.tcv_id }, 'measure')" class="mr-2" color="orange">mdi-eye-outline
                                        </v-icon>
                                        <v-chip x-small text-color="white" class="ml-0" :color="(item.spf_events) ? 'green lighten-2' : 'grey lighten-2'">{{item.spf_events}}</v-chip>
                                    </template>
                                </v-data-table>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                    </v-card>
                </template>
                
            </v-card-text>
        </v-card>
    </v-layout>
    <!-- Edition convention -->
    <v-dialog v-model="modalEdit" max-width="900">
        <edit-convention 
            v-if="modalType == 'convention'" :switchModal="switchModal" 
            :objTrackingSheet="objTrackingSheet" :tcv_id="itemsId.tcv_id" :routeState="routeState" >
        </edit-convention>
        <edit-specification 
            v-if="modalType == 'specification'" :switchModal="switchModal" 
            :objTrackingSheet="objTrackingSheet" :tcv_id="itemsId.tcv_id" :spf_id="itemsId.spf_id" >
        </edit-specification>
        <list-measure
            v-if="modalType == 'measure'" :switchModal="switchModal" 
            :objTrackingSheet="objTrackingSheet" :tcv_id="itemsId.tcv_id" :spf_id="itemsId.spf_id" >
        </list-measure>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale        from '../services/dateLocale';
import editConvention    from '../components/Edit_convention.vue'
import editSpecification from '../components/Edit_specification.vue'
import listmeasure       from '../components/List_measure.vue'
import { cacheGetters }  from "../store/cache.module"

import { usrGetters }   from "../store/user.module"

export default {
    name: 'tab_convention',
    props: ['objTrackingSheet', 'routeState'], 
    data: function () {
        return {
            loading: { getItem: false },
            locDateFormat: dateLocale, // Format de date
            modalEdit: false,
            modalType: '',
            items : [],
            specification: {},
            itemsId: {
                edit_id: 0,
                spf_id: 0,
                tcv_id: 0,
            },
            labels: {
                title: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Plans de gestion' : 'Contrats / avenants',
                newSheet: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Nouveau plan' : 'Nouvelle convention',
                typId : (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'ACQ' : 'COV',
                specification: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Plan' : 'Cahier des charges',
            },
            specificationHeaders: [],
            isAuthorized: usrGetters.getScopeRight,
        }
    },
    beforeMount: function(){
        this.specificationHeaders.push( { text: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Code mesure' : 'Cahier des charges', value:'cdr_code' } )
        if( this.objTrackingSheet.trs_typ_id !== 'SIT02' ){
            this.specificationHeaders.push( { text: 'Début',         value:'spf_date_start' } )
            this.specificationHeaders.push( { text: 'Fin',           value:'spf_date_expiration' } )
            this.specificationHeaders.push({ text: 'Commentaire',    value:'spf_custom_fields' })
        } else {
            this.specificationHeaders.push({ text: 'Intitulé',            value:'spf_custom_fields' })
        }
        this.specificationHeaders.push({ text: 'Surface',            value:'spf_area' })
        this.specificationHeaders.push({ text: 'Détails',            value:'spf_typ_id', sortable: false })
        this.specificationHeaders.push({ text: 'Intervention',       value:'spf_tcv_id', sortable: false })
    },
    mounted:function (){
        this.getItem().then( () => {
            for( const elem of this.items ){
                this.getSpecification( elem )
            }
        })
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                var searchCriteria = '?per_page=false&order_by=tcv_date,tcv_created_at&order_direction=desc' //page='+ this.pagination.page +'&
                searchCriteria    += '&tcv_trs_id=' +  this.objTrackingSheet.trs_id
                this.$http.get( '/trackingconvention/' + searchCriteria ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.items = response.data.data
                        this.items.forEach( (elem) => {
                            this.$set(this.specification, elem.tcv_id, [])
                        })
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getSpecification(convention){
            return new Promise( (resolve, reject) => {
                this.specification[convention.tcv_id] = []
                //this.loading.getItem = true
                var searchCriteria = '?per_page=false&extended=withcoderef,withevent'
                searchCriteria    += '&spf_tcv_id=' +  convention.tcv_id
                this.$http.get( '/specification/' + searchCriteria ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.specification[convention.tcv_id] = response.data.data
                        this.specification[convention.tcv_id].forEach( (elem) => {
                            elem.spf_expiration_above = -1
                            let conventionEnd    = new Date(convention.tcv_deadline).getTime()
                            let specificationEnd = new Date(elem.spf_date_expiration).getTime()
                            elem.spf_expiration_color = 'grey'
                            if( specificationEnd > conventionEnd ){
                                elem.spf_expiration_above = 1
                                elem.spf_expiration_color = 'red'
                            } else if( specificationEnd == conventionEnd ){
                                elem.spf_expiration_above = 0
                                elem.spf_expiration_color = 'green'
                            }
                            if( elem.spf_freeze_date ){
                                elem.spf_expiration_above = 0
                                elem.spf_expiration_color = 'green'
                            }
                        })
                    }

                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        updateConventionDate(specification){
            this.$root.$confirm('Modifier la convention', 'Confirmez vous cette date de fin pour la convention ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.$http.put( '/trackingconvention/' + specification.spf_tcv_id, { tcv_id: specification.spf_tcv_id, tcv_deadline: specification.spf_date_expiration } ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                        this.switchModal('convention', 0, false, true)
                    }).catch( (error) => {
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                        this.switchModal('-', 0, true, true)
                    })
                } else {
                    this.$http.put( '/specification/' + specification.spf_id, { spf_sta_id:"GEN01", spf_freeze_date: 1 } )
                    this.getItem().then( () => {
                        for( const elem of this.items ){
                            this.getSpecification( elem )
                        }
                    })
                }
            })
        },
        switchModal(name, itemId, mode, refresh){
            name    = name    || this.modalName
            mode    = mode    || 0
            refresh = refresh || false
            this.itemsId.spf_id = itemId.spf_id || 0
            this.itemsId.tcv_id = itemId.tcv_id || 0
            this.modalEdit = mode
            this.modalName = name
            if( refresh ){ 
                switch (this.modalName) {
                    case 'convention':
                        this.getItem().then( () => {
                            for( const elem of this.items ){
                                this.getSpecification( elem )
                            }
                        })
                        break;
                    case 'specification':
                        this.getItem().then( () => {
                            for( const elem of this.items ){
                                this.getSpecification( elem )
                            }
                        })
                        break;
                }
            }
            if( !mode ){
                this.modalType = ''
            }
            this.modalName = (mode) ? this.modalName : ''
        },
        openModal(itemId, type){
            type = type || 'convention'
            this.itemsId.spf_id = itemId.spf_id || 0
            this.itemsId.tcv_id = itemId.tcv_id || 0
            this.modalType = type
            this.modalEdit = true
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'edit-convention': editConvention,
        'edit-specification': editSpecification,
        'list-measure' : listmeasure
    }
}
</script>