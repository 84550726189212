<template>
  <v-app id="app">
    <v-navigation-drawer 
      width="250" style="z-index:900 !important;"
      mobile-breakpoint="600" fixed
      :mini-variant="miniVariant" :clipped="clipped" v-model="drawer" app hide-overlay dark>
      <v-toolbar flat short light>
        <v-list light class="pa-0" width="100%">
          <v-list-item light class="pa-0">
            <v-list-item-avatar>
              <v-tooltip bottom open-delay="1500">
                  <template v-slot:activator="{ on }">
                    <img v-on="on" class="hidden-sm-and-up" @click.stop="drawer = !drawer" src="@/assets/logo_small.svg" style="cursor:pointer;" />
                    <img v-on="on" class="hidden-xs-only"   @click.stop="miniVariant = !miniVariant" src="@/assets/logo_small.svg" style="cursor:pointer; margin-right: 16px;" />
                  </template>
                  <span>Ouvrir / fermer le menu</span>
              </v-tooltip>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title @click="switchAppMode()" style="cursor:pointer;" >
                <b v-if="(appMode == 'compensea')">CompenSEA</b>
                <b v-else-if="(appMode == 'foncea')">Foncea</b>
                <b v-else>Erreur</b>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom open-delay="1500">
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="pt-1 pr-1 hidden-sm-and-up" @click.stop="drawer = !drawer">mdi-backburger </v-icon>
                  </template>
                  <span>Ouvrir / fermer le menu</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="1500">
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="pt-1 pr-1 hidden-xs-only" @click.stop="miniVariant = !miniVariant">mdi-backburger</v-icon>
                  </template>
                  <span>Ouvrir / fermer le menu</span>
              </v-tooltip>              
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dark dense>
        <template v-for="(item, i) in items" >
          <v-list-group v-if="item.active && item.items" :key="i" v-bind:group="item.group" :prepend-icon="item.icon" style="width:100%;">
            <template v-slot:activator>
            <v-subheader v-if="item.header && !miniVariant" :key="i" class="pl-0 theme--dark" style="margin-right:auto;">
              {{ item.header }}
            </v-subheader>
            <v-list-item v-else-if="!miniVariant" :to="item.href" :title="item.title" class="pa-0">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-subheader v-else :title="item.title" class="pl-0 theme--dark">
              <v-icon v-html="item.icon" dark></v-icon>
            </v-subheader>
            </template>
            <!-- Sous menus -->
            <template v-for="subItem in item.items" >
              <v-list-item v-if="subItem.active" :to="subItem.href" :title="subItem.title" :key="subItem.href"
                          v-bind:router="!subItem.target" ripple
                          v-bind:disabled="subItem.disabled" v-bind:target="subItem.target" >
                <v-list-item-action v-if="subItem.icon" >
                  <v-icon v-html="subItem.icon" dark></v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <!-- Divider -->
          <v-divider v-else-if="item.active && item.divider" :key="i"></v-divider>
          <!-- Sous titre -->
          <v-subheader v-else-if="item.active && item.header && !miniVariant" :key="i" class="theme--dark">{{ item.header }}</v-subheader>
          <v-list-item v-else-if="item.active && !item.header" :key="i" :to='item.href' :title="item.title">
            <v-list-item-action>
              <v-icon v-html="item.icon" dark></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app :color="colorMode[appMode]" dark short elevation="2" style="z-index:100 !important;">
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" /><v-toolbar-title>Filtres</v-toolbar-title> -->
      <v-menu v-if="appMode == 'compensea'" v-model="filterMenu" :close-on-content-click="false" :nudge-width="600" offset-y z-index="900">
        <template v-slot:activator="{ on: filterMenu }">
          <v-tooltip right open-delay="1500" >
              <template v-slot:activator="{ on: tooltip }">
                <v-badge :content="filterCount" :value="filterCount" color="red" overlap >
                  <v-btn text v-on="{ ...tooltip, ...filterMenu }">
                      <v-icon >mdi-filter-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <span>Filtre général</span>
          </v-tooltip>
        </template>
        <FilterMenu class="mt-2" style="z-index:900 !important;" :filterDiff="filterDiff" :filterCount.sync="filterCount" :filterView.sync="filterMenu" />
      </v-menu>
      <v-menu v-if="appMode == 'compensea'" v-model="siteMenu" :close-on-content-click="false" :nudge-width="600" offset-y z-index="1100">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip right open-delay="500">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip, ...menu }" icon slot="activator" v-show="siteTabBt">
                  <v-badge color="red" class="mt-1" :content="bookmarkCount" :value="bookmarkCount">
                    <v-icon>mdi-card-bulleted-outline</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Voir les sites en favoris</span>
          </v-tooltip>
        </template>
        <view-opencard class="mt-2" style="z-index:900 !important;" :tabsheetCount="bookmarkCount" :menuState="openCardState"></view-opencard>
      </v-menu>
      <v-spacer></v-spacer>
      <v-menu v-model="alertMenu" :close-on-content-click="false" :nudge-width="250" offset-y z-index="1100">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom open-delay="1500">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip, ...menu }" icon slot="activator" v-show="alertBelt">
                  <v-badge color="red" class="mt-1" :content="alertCount" :value="alertCount">
                    <v-icon>mdi-bell</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Liste des alertes</span>
          </v-tooltip>
        </template>
        <view-openalert class="mt-2" style="z-index:900 !important;" :filterDiff="filterDiff" :menuState="openCardState"></view-openalert>
      </v-menu>
      <!-- Versionning -->
      <v-btn text style="text-transform: capitalize;" @click="dialChangelog = !dialChangelog">
        <v-icon left >mdi-information-outline</v-icon>
        {{ $APP_VER }}
      </v-btn>
      <v-menu v-model="userMenu" offset-y transition="slide-x-transition" bottom left content-class="elevation-0" style="z-index:900">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" style="text-transform: capitalize;">
            <v-avatar :color="colorMode[appMode]"><v-icon dark class="ml-0">mdi-account-circle</v-icon></v-avatar>
            {{user.usr_firstName}}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-btn @click="logout()">
          <v-icon left>mdi-logout-variant</v-icon>Se déconnecter
        </v-btn>
      </v-menu>
    </v-app-bar>
    <!-- Router view page content -->
    <v-main v-if="dataReady" style="background-color:#f6f8f8; position: relative;">
      <router-view :toast="$root.$toast" :filterView="filterMenu"></router-view>
    </v-main>
    <!-- Router view slider -->
    <router-view name="modal" :toast="$root.$toast" :siteTabState="siteTabState" :filterView="filterMenu"></router-view>
    <ConfirmDial   ref="confirm" />
    <ChangelogDial ref="changelog" :diagState.sync="dialChangelog" style="z-index:2000;" />
    <ToastDialog   ref="toast" />
    <!-- <v-snackbar style="z-index:2000; white-space: pre;"
      :timeout="toastOption.timeout" :color="toastOption.color"
      :top="toastOption.top" :bottom="toastOption.bottom" 
      :left="toastOption.left" :right="toastOption.right" v-model="enableToast" >
        {{toastOption.text}}
    </v-snackbar> -->
  </v-app>
</template>

<script>
import FilterMenu    from '@/components/ui/FilterMenu.vue'
import vOpencard     from '@/components/View_openedcard.vue'
//import vOpenalert   from '@/components/View_alert.vue'
import ChangelogDial from '@/components/ui/ChangelogDialog.vue'
import ConfirmDial   from '@/components/ui/ConfirmDialog.vue'
import ToastDialog  from '@/components/ui/Toast.vue'

import { actions }   from "@/store/auth.module"
import menu          from '@/confnav'
import { cacheData, cacheMutations, cacheGetters, cacheActions } from "@/store/cache.module"
import { scopeMutations } from "@/scopeCredential"

export default {
  name: 'mainApp',
  components: {
    FilterMenu,
    ChangelogDial,
    ConfirmDial,
    ToastDialog,
    'view-opencard' : vOpencard,
  },
  data () {
    return {
        appMode: 'compensea',
        colorMode: { compensea: 'indigo', foncea: '#43A047', error: '#B71C1C' },
        clipped: false,
        drawer: false,
        fixed: false,
        items: [],
        miniVariant: false,
        right: true,
        rightDrawer: false,
        title: process.env.VUE_APP_TITLE,
        option: {},
        //enableToast: false,
        //toastDefault: { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré', timeout: 2000 },
        //toastOption:  { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré', timeout: 2000 },
        filterMenu: false, 
        siteMenu: false, 
        siteTabBt: true,
        alertBelt: false,
        userMenu: false,
        alertMenu: false,
        dataReady: false,
        filterCount: 0,
        filterDiff: 0,
        alertCount: 0,
        tabsheetCount: 0,
        dialChangelog: false,
    }
  },
  computed: {
      user(){
        return cacheData.user
      },
      bookmarkCount(){
          var total = 0
          var item
          for( item in cacheData.bookmark ){
              if( item ){
                  total++
              }
          }
          return total
      }
  },
  beforeMount: function(){
    //this.appMode = cacheGetters.getSearchBarItem('appmode') || 'compensea'
    //Chargement du cache
    cacheActions.initCacheFromStorage().then( () => {
        Promise.all([ 
          this.getType(), this.getState(), this.getUserInfo(), this.getDepartment(), 
          this.getWatershed(), this.getSar(), this.getCoderef(), this.getSpeciesGroup(),
          //this.getSpecies(),
        ]).then( () => {
          cacheMutations.setStates( cacheGetters.getStates() )
          cacheMutations.setTypes( cacheGetters.getTypes() )
          cacheMutations.setUser( cacheGetters.getUser() )
          cacheMutations.setDepartment( cacheGetters.getDepartment() )
          cacheMutations.setWatershed( cacheGetters.getWatershed() )
          cacheMutations.setSar( cacheGetters.getSar() )
          //cacheMutations.setSpecies( cacheGetters.getSpecies() )
          cacheMutations.setSpeciesGroup( cacheGetters.getSpeciesGroup() )
          cacheMutations.setCoderef( cacheGetters.getCoderef() )
          cacheActions.storeCacheToStorage() //this.$storage.localStorage.set( this.$APP_COD + "_cache", cacheData)
        }).then( () => {
          let usrRole  = cacheGetters.getUserRole()
          if( usrRole.indexOf('FONCEA') !== -1 ){
            this.appMode = 'foncea'
            cacheMutations.setAppModule('foncea')
            if( this.$route.path == '/' || this.$route.meta.appmode === undefined || this.$route.meta.appmode.indexOf( 'foncea' ) === -1 ) { 
              this.$router.push('/foncea') 
            }
          } else {
            cacheMutations.setAppModule('compensea')
            if( this.$route.path == '/' ) { this.$router.push('/dashboard') } //todoo auto
          }
          // Fix appmode for super ADMIN_USER
          if( this.$route.meta.appmode !== undefined && this.$route.meta.appmode.indexOf( 'foncea' ) > -1 && usrRole == 'ADMIN_USER' ){
            this.appMode = 'foncea'
            cacheMutations.setAppModule('foncea')
          }
          this.buildMenu()
          this.dataReady = true

        }).catch( () => {
          this.buildMenu()
          this.dataReady = true
        })
    })
  },
  mounted: function (){
    // Global Confirm Dialog
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$toast   = this.$refs.toast.open
  },
  methods: {
    buildMenu(){
      // Right on menu by role
      //let usrRole  = cacheGetters.getUserRole()
      menu.menu.forEach( (element) => {
        let right = (element.right) ? element.right : 'index'
        element.active = (this.$isAuthorized( element.scope, right ) && element.appmode.indexOf( this.appMode ) !== -1) ? true : false
        if( element.items !== undefined ){
          element.items.forEach( (item) => {
            right = (item.right) ? item.right : 'index'
            item.active = (this.$isAuthorized( item.scope, right ) && item.appmode.indexOf( this.appMode ) !== -1) ? true : false
          })
        }

      })
      this.items = menu.menu
      //this.items = ctrlMenu
      if( window.innerWidth > 600 ){
        this.miniVariant = false
        this.drawer      = true
      }
    },
    getType () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/types?per_page=false' ).then( (response) => {
          let types = {}
          response.data.data.forEach( (item) => {
            if( types[ item.typ_id.substring(0, 3) ] == undefined ){
              types[ item.typ_id.substring(0, 3) ] = {}
            }
            types[ item.typ_id.substring(0, 3)][item.typ_id] = item
          })
          cacheMutations.setTypes( types )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getState () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/states?per_page=false' ).then( (response) => {
          let states = {}
          response.data.data.forEach( (item) => {
            if( states[ item.sta_id.substring(0, 3) ] == undefined ){
              states[ item.sta_id.substring(0, 3) ] = {}
            }
            states[ item.sta_id.substring(0, 3) ][item.sta_id] = item
          })
          cacheMutations.setStates( states )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getUserInfo(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/users/me/?include=useraccess' ).then( (response) => {
          cacheMutations.setUser( response.data.data )
          if( response.data.data && response.data.data.useraccess ){
            scopeMutations.setAccess( response.data.data.useraccess.data.uac_access_json )
          }
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getDepartment(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/departmentbyplot' ).then( (response) => {
          cacheMutations.setDepartment( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getWatershed(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/watershed' ).then( (response) => {
          cacheMutations.setWatershed( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getSar(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/smallagrregion/?per_page=false&sar_concerned=1' ).then( (response) => {
          cacheMutations.setSar( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getSpecies(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/species' ).then( (response) => {
          cacheMutations.setSpecies( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getSpeciesGroup(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/speciesgroup' ).then( (response) => {
          cacheMutations.setSpeciesGroup( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getCoderef(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/coderef/?per_page=false' ).then( (response) => {
          cacheMutations.setCoderef( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    //toast (option) {
    //  if( option !== null && typeof option === 'object' ){
    //    var prop
    //    for( prop in this.toastDefault ){
    //        if( option[prop] !== undefined ){
    //            this.toastOption[prop] = option[prop]
    //        }
    //    }
    //  }
    //  this.enableToast = true
    //},
    hideToast () {
      this.snackbar = false
    },
    updateFilter(num){
      this.filterDiff = num
    },
    openCardState(state){
      this.siteMenu = state
    },
    siteTabState(state){
      this.siteTabBt = state
    },
    logout(){
      if( process.env.VUE_APP_OAUTH_IDP === 'true' ){
        actions.logout().then( () => {
          cacheActions.deleteCacheStorage()
          this.$router.push({ path: '/login' })
        })
      } else {
        actions.logout().then( () => {
          cacheActions.deleteCacheStorage()
          const uri = `?returnTo=${encodeURIComponent( process.env.VUE_APP_URL + '#/login' )}`
          let samlIpd = process.env.VUE_APP_SAML_IDP || 'vinci'
          window.location = process.env.VUE_APP_API_URL + 'saml2/' + samlIpd + '/logout' + uri
        })
      }
    },
    switchAppMode(){
      let usrRole     = cacheGetters.getUserRole()
      let switchRight = usrRole.indexOf('ADMIN')
      if( this.appMode == 'compensea' && switchRight !== -1 ){
        this.appMode = 'foncea'
        cacheMutations.setAppModule('foncea')
        this.miniVariant = true
        this.$router.push({ path: '/foncea' })
      } else if( switchRight !== -1 ) {
        this.appMode = 'compensea'
        cacheMutations.setAppModule('compensea')
        this.$router.push({ path: '/dashboard' })
      }
      //cacheMutations.setSearchBarItem('appmode', this.appMode)
      this.buildMenu()
    }
  }
}
</script>
