<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('compensationhabitatdet', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
        <v-form ref="userForm" v-model="validForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="stateItem" v-model="objItem.chd_sta_id" label="Type"
                    item-text="sta_label" item-value="sta_id" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Surface" v-model="objItem.chd_area" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                 <v-checkbox v-model="objItem.chd_bio_state"   :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )" label="Biologie"></v-checkbox>
                 <v-checkbox v-model="objItem.chd_hydro_state" :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )" label="Hydrologique"></v-checkbox>
                 <v-checkbox v-model="objItem.chd_epur_state"  :disabled="!$isAuthorized( 'TrackingSheetEcology', 'store' )" label="Epuratoire"></v-checkbox>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="addHabitat" :title="'Habitat / habitat simplifié'" ressource="habitats" :listObject.sync="autoComplete.chd_hab_id"
                    :listParent.sync="autoCompleteSource.hab" :fields="{ index: 'hab_id', text:'hab_name', search:'hab_name', multiSelect:false, preprendIcon:'mdi-home-map-marker' }"
                    class="ml-0 mr-0" @change="saveHabitat">
                </auto-multi>
                <v-data-table :headers="chdHabitatHeaders" :items="chdItems" hide-default-footer hide-default-header :items-per-page="50" class="elevation-2" :custom-filter="searchFilter">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>
                                <v-alert class="mb-0" border="left" dense colored-border type="info">Habitats</v-alert>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn v-if="itemId" outlined rounded small color="green" @click="addHabitat = true">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.chh_chd_id`]="{ item }">
                        <v-icon small class="mr-2" color="grey" @click="deleteHabitat(item.chh_chd_id, item.chh_hab_id)">mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0 && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem && $isAuthorized( 'TrackingSheetEcology', 'store' )" :loading="saveProgress" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('compensationhabitatdet', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"
import { toolHelper }  from '../mixins/tool_helper.js'

export default {
    name: 'edit_compensation_habitat_det',
    props: ['switchModal', 'objTrackingSheet', 'itemId', 'itemStaId'],
    mixins: [ toolHelper ],
    data: function () {
        return {
            cardTitle: 'Caractéristiques détaillées du site de compensation',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            validForm   : true,
            loading: { getItem: true },
            stateItem: cacheGetters.getStates('CHD'),
            objItem: { 
                chd_id: this.itemId, chd_trs_id: this.objTrackingSheet.trs_id, chd_sta_id : this.itemStaId,
                chd_bio_state : '', chd_hydro_state : '', chd_epur_state : '',
                chd_area : '', chd_order : '',
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            autoComplete: {
                chd_hab_id: null
            },
            autoCompleteSource:{
                hab: []
            },
            addHabitat: false,
            chdItems: [],
            chdHabitatHeaders: [
                { text: 'Habitat',  value:'hab_name' },
                { text: '',         value:'chh_chd_id' },
            ],
        }
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
            this.getHabitats()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/compensationhabitatdet/' + this.itemId ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/compensationhabitatdet/' + this.itemId, this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationhabitatdet', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('compensationhabitatdet', 0, 1)
                })
            } else {
                this.$http.post( '/compensationhabitatdet', this.objItem ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('compensationhabitatdet', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('compensationhabitatdet', 0, 0)
                });
            }
        },
        deleteItem(){
            this.$root.$confirm('Supprimer ces caractéristiques', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && this.itemId > 0 ){
                    this.$http.delete( '/compensationhabitatdet/' + this.itemId ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                        this.switchModal('compensationhabitatdet', 0, 0, true)
                    }).catch( (error) => {
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                        this.switchModal('compensationhabitatdet', 0, 1)
                    })
                }
            })
        },
        getHabitats(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/chdhabitat/?chh_chd_id=' + this.itemId + '&extended=withhabitat' ).then( (response) => {
                    this.chdItems = response.data.data
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteHabitat(chd_id, hab_id){
            this.$root.$confirm('Dissocier l\'habitat', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && chd_id > 0 && hab_id > 0){
                    this.$http.delete( '/chdhabitat/' + this.itemId + '/habitats/' + hab_id ).then( () => {
                        this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Habitat dissocié' })
                        this.addHabitat = false
                        this.getHabitats()
                    }).catch( (error) => {
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                        this.switchModal('chdhabitat', 0, 1)
                    })
                }
            })
        },
        saveHabitat(chdHabId){
            if( chdHabId !== undefined && chdHabId > 0 ){
                this.$http.post( '/chdhabitat/', {chh_chd_id:this.itemId, chh_hab_id: chdHabId} ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Habitat ajouté' })
                    this.addHabitat = false
                    this.getHabitats()
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    //this.switchModal('chdhabitat', 0, 1)
                })
            }
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>