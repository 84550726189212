<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Liste sites</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="downloadSite()" color="primary" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-cloud-download-outline</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Télécharger la liste des fiches</span>
        </v-tooltip>
        <v-btn v-if="isAuthorized( 'TrackingSheet', 'store' )" rounded to="/site/0" color="success" depressed class="white--text">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4 mb-2">
    <v-data-table ref="sheetTable" v-bind:headers="headers" v-bind:items="items" v-bind:search="search" @current-items="getFilteredItems"
        :page.sync="pagination.page" :items-per-page="pagination.itemsPerPage" @page-count="pagination.pageCount = $event"
        footer-props.items-per-page-text="Lignes par page" hide-default-footer :custom-filter="searchFilter">
        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.trs_id" @click="openSite(item.trs_id)" style="cursor:pointer;">
            <td class="text-xs-left">{{ item.trs_coddt }}</td>
            <td class="text-xs-left">
                <template v-for="(codes, index) in item.convention">
                    <v-chip  :key="index" x-small outlined label :color="( item.trs_sta_id == 'GEN01' ) ? getCacheType('SIT', item.trs_typ_id).typ_color : 'grey'">
                        {{ index }}
                    </v-chip>
                </template>
            </td>
            <td class="text-xs-left">{{ item.trs_name }}</td>
            <td class="text-xs-left">{{ item.ctt_company_name }}</td>
            <td class="text-xs-left">
                <v-chip small outlined label :color="( item.trs_sta_id == 'GEN01' ) ? getCacheType('SIT', item.trs_typ_id).typ_color : 'grey'">
                    {{ getCacheType('SIT', item.trs_typ_id).typ_name }}
                </v-chip>
            </td>
            <td class="text-xs-left">{{ item.tow_name }} ({{item.dep_code}})</td>
            <td class="text-xs-left">{{ item.was_name }}</td>
            <td class="text-xs-left">{{ item.trs_area_plot.toFixed(2) }}</td>
            <td class="text-xs-left"><v-btn :href="'#/trackingsheet/site/' + item.trs_id" target="new" @click.stop small rounded outlined color="primary" dark><v-icon>mdi-application-export</v-icon></v-btn></td>
        </tr>
        </tbody>
        <tfoot>
            <tr>
                <td :colspan="headers.length - 2 "></td>
                <td colspan="2" class="text-xs-left font-weight-bold" style="background-color:#E3F2FD;">{{ calcTotalArea }}</td>
            </tr>
        </tfoot>
        </template>
        <template slot="no-data">
        <v-alert :value="true" color="error" icon="mdi-alert">
            Aucune fiche
        </v-alert>
        </template>
    </v-data-table>
    </v-card>
    <v-card-actions class="">
        <v-container fluid>
            <v-row>
                <v-col cols="5">
                    <span v-show="pagination.totalFiltered">{{pagination.totalFiltered}} sur </span>
                    <span>{{pagination.totalItems}} résultats</span>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-text-field ref="pageinput" @focus="$event.target.select()" v-model.number="pagination.page" @input.native="changePage($event)" outlined dense label="Page"></v-text-field>
                </v-col>
                <v-col cols="4" class="text-right">
                    <v-pagination v-model="pagination.page" :length="pagination.pageCount" :total-visible="8" ></v-pagination>
                </v-col>
            </v-row>
        </v-container>
    </v-card-actions>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale'
import { cacheGetters, cacheMutations } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'
import { toolHelper }  from '../mixins/tool_helper.js'

import { usrGetters }   from "../store/user.module"

export default {
    name: 'trackingsheet',
    props: ['filterView'],
    mixins: [ trsObj, toolHelper ],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            pagination: { page: 1, totalItems: 0, itemsPerPage: 12, totalPage: 1, limit: 0, pageCount: 0, totalFiltered: 0 },
            headers: [
            { text: 'CODDT',          align: 'left', value: 'trs_coddt'},
            { text: 'Convention',     align: 'left', value: 'tcv_codes'}, //tcv_codes trs_mc2_code
            { text: 'Nom',            align: 'left', value: 'trs_name'},
            { text: 'Société',        align: 'left', value: 'ctt_company_name'},
            { text: 'Type',           value: 'trs_typ_id' },
            { text: 'Commune',        align: 'left', value: 'tow_name' },
            { text: 'Bassin Versant', align: 'left', value: 'was_name' },
            { text: 'Surface',        align: 'left', value: 'trs_area_specification' }, // engaged
            { text: '-',              align: 'left', value: 'trs_mc2_code' }
            ],
            items: [],
            filteredItems: [],
            locDateFormat: dateLocale, // Format de date
            enableAdd: true,
            modalEvent : false,
            isAuthorized: usrGetters.getScopeRight,
            pageImputDelai: undefined
        }
    },
    watch: {
        filterView: function(){
            if( !this.filterView ){
                this.getSites()
            }
        }
    },
    computed: {
        pages () {
            if (this.pagination.totalItems == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
        calcTotalArea() {
            let total = 0
            this.items.forEach(element => {
                total += element.trs_area_plot
            })
            if( this.search && this.search.length > 0 ){
                return ''
            } else {
                return (Math.round(total * 100) / 100).toFixed(2)
            }
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('trackingsheet') || ''
        this.getSites()
    },
    methods: {
        getSites(){
            this.items = []
            this.pagination.page = 1
            this.getTrackingSheetIndex(1, false, '&extended=withconvention').then( () => {
                if( this.metaTrackingSheet.pagination.total !== undefined && this.metaTrackingSheet.pagination.total !== '0' ){
                    this.pagination.totalItems  = parseInt(this.metaTrackingSheet.pagination.total)
                } else {
                    this.pagination.totalItems = 1
                }
                // tcvCodes for chips display
                this.listTrackingSheet.forEach( (elem) => {
                    elem.convention = {}
                    if( elem.tcv_codes ){
                        let tcvCodes = elem.tcv_codes.split(',')
                        tcvCodes.forEach( (code) => {
                            elem.convention[ code ] = code
                        })
                    }
                })
                this.items = this.listTrackingSheet
            })
        },
        saveVersion(){
                this.getEvent()
                this.enableAdd = true
        },
        openSite(trs_id){
            this.$router.push({ path: '/trackingsheet/site/' + trs_id })
        },
        downloadSite(){
            let searchCriteria = this.getTrackingSheetFilter()
            this.$http.customRequest({ method: 'get', url: '/report/trackingsheet/' + searchCriteria, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport_1.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
            //this.pagination.page  = (index - 1)
            this.getSites()
        },
        getFilteredItems(e){
            let totalFiltered = e.length
            if( totalFiltered >= this.pagination.itemsPerPage ){
                this.pagination.totalFiltered = 0
            } else {
                this.pagination.totalFiltered = totalFiltered
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('trackingsheet', this.search)
        },
        changePage(e){
            clearTimeout( this.pageImputDelai )
            this.pageImputDelai = setTimeout( () => {
                e.target.select()
            }, 700)
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        
    }
}
</script>

<style lang="css">
.v-pagination{
    width: 450px;
}
</style>