<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="primary" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{labels.title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('-', 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="pa-2">
        <v-card class="ma-0">
        <v-tabs v-model="activeTab" grow light show-arrows="mobile">
            <v-tabs-slider color="green"></v-tabs-slider>
            <!-- <v-tab><v-icon class="mr-1">mdi-file-document-box-check</v-icon><span class="hidden-sm-and-down">Procédures</span></v-tab> -->
            <v-tab class="pl-1 pr-1"><v-icon class="hidden-md-and-up mr-1">mdi-file</v-icon><span class="hidden-sm-and-down">{{ labels.tabTitle }}</span></v-tab>
            <v-tab class="pl-1 pr-1" :disabled="!objSpecfication.spf_id">
                <v-icon class="hidden-md-and-up mr-1" :disabled="true">mdi-file</v-icon>
                <span class="hidden-sm-and-down">
                <v-badge color="green" :content="(doc_ids) ? doc_ids : '0'">Fichiers</v-badge>
                </span>
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-card class="ma-0" v-if="activeTab === 0">
            <v-card-text>
            <v-form ref="userForm" v-model="validForm" lazy-validation>
                <v-layout row wrap>
                <v-flex xs6 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-menu ref="datestart" v-model="dateMenu.datestart"
                        :return-value.sync="objSpecfication.spf_date_start"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( objSpecfication.spf_date_start, 'fr' )"
                                label="Date début" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="objSpecfication.spf_date_start">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.datestart = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.datestart.save( objSpecfication.spf_date_start )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs6 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-menu ref="dateexpiration" v-model="dateMenu.dateexpiration"
                        :return-value.sync="objSpecfication.spf_date_expiration"
                        :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                        offset-y min-width="290px" style="z-index:1500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                v-bind="attrs" :value="locDateFormat.formatLocale( objSpecfication.spf_date_expiration, 'fr' )"
                                label="Date fin" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                            </v-text-field>
                        </template>
                        <v-date-picker locale="fr-fr" no-title scrollable v-model="objSpecfication.spf_date_expiration">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dateMenu.dateexpiration = false">Annuler</v-btn>
                            <v-btn color="primary" @click="$refs.dateexpiration.save( objSpecfication.spf_date_expiration )">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex xs6 class="pa-2">
                    <auto-multi v-if="!loading.getItem" :title="(objTrackingSheet.trs_typ_id == 'SIT02') ? 'Equivalent code cahier des charges' : 'Cahier des charges'" :ressource="false" :listObject.sync="autoComplete.spf_cdr_id"
                        :listParent.sync="autoCompleteSource.cdr" 
                        :fields="{ 
                            index: 'cdr_id', text:'cdr_code,cdr_description', search:'cdr_code', multiSelect:false, preprendIcon:'mdi-sim',
                            //params: 'cdr_typ_id_sit=' + objTrackingSheet
                        }">
                    </auto-multi>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-select 
                        :items="typeIndex" v-model="objSpecfication.spf_typ_id_index" label="Type d'indexation"
                        item-text="typ_name" item-value="typ_id" >
                    </v-select>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Valeur d'indexation" type="number" v-model="objSpecfication.spf_update_value" required></v-text-field>
                </v-flex>
                <v-flex xs1 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-checkbox :true-value="1" :false-value="0" v-model="objSpecfication.spf_update_tax" label="TVA"></v-checkbox>
                </v-flex>
                <v-flex xs2 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Taux" type="number" v-model="objSpecfication.spf_invoice_tax_value"></v-text-field>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-checkbox :true-value="1" :false-value="0" v-model="objSpecfication.spf_payment_direct" label="Paiement direct"></v-checkbox>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Fréquence d'indexation" type="number" v-model="objSpecfication.spf_payment_frequency" required></v-text-field>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-select 
                        :items="unitFrequency" v-model="objSpecfication.spf_payment_unit_freq" label="Type fréquence d'indexation"
                        item-text="typ_name" item-value="typ_id" >
                    </v-select>
                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-text-field label="Surface" type="number" @change="areaChange = true" v-model="objSpecfication.spf_area" required></v-text-field>
                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-text-field label="Linéaire" type="number" v-model="objSpecfication.spf_area_linear" required></v-text-field>
                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-text-field label="Non surfacique (marres...)" type="number" v-model="objSpecfication.spf_unit_point" required></v-text-field>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Montant" type="number" v-model="objSpecfication.spf_amount_ht" required prepend-icon="mdi-currency-eur"></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-2" v-show="(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Code mesure" v-model="objSpecfication.spf_custom_fields['spf_code']"></v-text-field>
                </v-flex>
                <v-flex xs3 class="pa-2" v-show="!(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-checkbox :true-value="1" :false-value="0" v-model="objSpecfication.spf_delegation" label="Délégué à LISEA"></v-checkbox>
                </v-flex>
                <v-flex xs12 class="pa-2">
                    <v-text-field :label="(objTrackingSheet.trs_typ_id == 'SIT02') ? 'Intitulé mesure' : 'Commentaires'" v-model="objSpecfication.spf_custom_fields['spf_precision']" required prepend-icon="mdi-pin"></v-text-field>
                </v-flex>
                <v-flex xs12 class="pa-2" v-if="(objTrackingSheet.trs_typ_id == 'SIT02')">
                    <v-text-field label="Commentaires" v-model="objSpecfication.spf_custom_fields['spf_details']" required prepend-icon="mdi-pin"></v-text-field>
                </v-flex>
                </v-layout>
            </v-form>
            </v-card-text>
        </v-card>
        <tab-doc v-if="activeTab === 1 && showTab == true" :doc_xxx_id="objSpecfication.spf_id" :table_id="'spf'" 
            :routeState="{ siteView:false, siteEdit:true, view:false, edit:true, add:true, state: 'modifier' }" style="min-height: 474px;">
        </tab-doc>
        </v-card>
    </v-card-text>
    <!-- <event-tab :table_id="'adu'" :usr_type="'control'" ></event-tab> -->
    <v-card-actions style="background-color:white;">
        <v-btn class="ma-0" color="error" @click.native="deleteSpecification()" :disabled="saveProgress">
            <v-icon left>mdi-trash-can</v-icon>Supprimer
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('-', 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale       from '@/services/dateLocale';
import { cacheGetters } from "@/store/cache.module"
import autoCompMulti    from '@/components/Autocomplete_multi.vue'
import documentTab      from '@/components/Tab_document.vue'
import { spfObj } from '@/mixins/spf_obj.js'
import { trackingsheetUpdateService } from '@/rxjsServices';

export default {
    name: 'edit_specification',
    props: ['switchModal', 'objTrackingSheet', 'spf_id', 'tcv_id'],
    mixins: [ spfObj ],
    data: function () {
        return {
            dateMenu : {},
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            validForm   : true,
            typeSpec : cacheGetters.getTypes('SPF'),
            typeIndex: cacheGetters.getTypes('INR'),
            //objSpecfication: {
            //    spf_id: 0, spf_trs_id:	this.objTrackingSheet.trs_id, spf_tcv_id: this.tcv_id, spf_typ_id: 'SPF01', spf_sta_id: 'GEN01',
            //    spf_cdr_id: 0, spf_date_start: null, spf_date_expiration: null, 
            //    spf_typ_id_index : 'INR01', spf_update_value : null, spf_update_tax : '',
            //    spf_payment_direct : '', spf_payment_frequency : '', spf_payment_unit_freq : '', spf_invoice_date : '', spf_invoice_tax_value: null,
            //    spf_amount_ht: 0, spf_area: 0, spf_delegation:0, spf_custom_fields: {}
            //},
            areaChange: false,
            unitFrequency: [
                { typ_name: 'Jour', typ_id: 'd' },
                { typ_name: 'Mois', typ_id: 'm' },
                { typ_name: 'Année', typ_id: 'y' } 
            ],
            form:{},
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            locDateFormat: dateLocale, // Format de date
            autoComplete: {
                spf_cdr_id: null,
            },
            autoCompleteSource:{
                cdr: cacheGetters.getCoderef( (this.objTrackingSheet.trs_typ_id == 'SIT01') ? 'SIT01' : false ),
            },
            doc_ids: 0,
            activeTab: 0, showTab: true,
            labels: {
                title: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Détail mesure' : 'Détails cahier des charges',
                tabTitle: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Mesure' : 'Cahier des charges',
                type: (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'Type plan' : 'Type de convention',
                typId : (this.objTrackingSheet.trs_typ_id == 'SIT02') ? 'ACQ' : 'COV',
            },
        }
    },
    computed: { },
    mounted:function (){
        this.objSpecfication.spf_date_start = this.locDateFormat.getToday('en')
        this.objSpecfication.spf_id = this.spf_id
        if( this.objSpecfication.spf_id > 0){
            this.getItem().then( () => {
                this.doc_ids = 0
                this.getAttachedDocument()
            })
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/specification/' + this.objSpecfication.spf_id ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objSpecfication[prop] = response.data.data[prop]['data']
                        } else {
                            this.objSpecfication[prop] = response.data.data[prop]
                        }
                    }
                    this.autoComplete.spf_cdr_id = this.objSpecfication.spf_cdr_id
                    this.objSpecfication.spf_custom_fields = (!this.objSpecfication.spf_custom_fields) ? {} : this.objSpecfication.spf_custom_fields
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        async saveForm() {
            // Enregistrement du formulaire
            this.saveProgress = true
            this.objSpecfication.spf_freeze_date = 0
            this.objSpecfication.spf_cdr_id = this.autoComplete.spf_cdr_id || null
            if( this.objSpecfication.spf_id > 0 ){
                let result = await this.putSpecificationUpdate()
                if( this.objTrackingSheet.trs_typ_id !== 'SIT02' ){
                    await this.computeArea()
                }
                result && this.switchModal('specification', { spf_id: this.objSpecfication.spf_id, tcv_id: this.objSpecfication.spf_tcv_id }, 0, 1)
            } else {
                let result = await this.postSpecificationStore()
                if( this.objTrackingSheet.trs_typ_id !== 'SIT02' ){
                    await this.computeArea()
                }
                result && this.switchModal('specification', { spf_id: this.objSpecfication.spf_id, tcv_id: this.objSpecfication.spf_tcv_id }, 0, 1)
            }
        },
        computeArea(){
            return new Promise( (resolve) => {
                if( this.areaChange ){
                    this.$root.$confirm('Re-calculer la surface du site ?', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                        if( confirm ){
                            this.$http.get( '/synchro/trackingarea/' + this.objSpecfication.spf_trs_id ).then( () => {
                                trackingsheetUpdateService.sendTrackingsheet('update')
                            })
                            resolve('resolved')
                        } else {
                            resolve('resolved')
                        }
                    })
                } else {
                    resolve('resolved')
                }
            })
        },
        deleteSpecification(){
            this.$root.$confirm('Supprimer ce cahier des charges', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm && this.objSpecfication.spf_id > 0 ){
                    this.$http.delete( '/specification/' + this.objSpecfication.spf_id ).then( () => {
                        this.switchModal('specification', { spf_id: 0, tcv_id: this.objSpecfication.spf_tcv_id }, 0, 1)
                    }).catch( () => { 
                        this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur de suppression' })
                    })
                }
            })
        },
        getAttachedDocument(){
            var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
            searchCriteria    += '&doc_xxx_id=' +  this.objSpecfication.spf_id + '&doc_table_id=spf'
            this.$http.get( '/documents/' + searchCriteria ).then( (response) => {
                if( response.data.meta.pagination.total !== undefined && response.data.meta.pagination.total !== '0' ){
                    this.doc_ids  = parseInt(response.data.meta.pagination.total)
                }
            })
        }
    },
    components: {
        'auto-multi': autoCompMulti,
        'tab-doc' : documentTab,
    }
}
</script>