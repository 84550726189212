/* *****************************
    Objet : Tools helper
******************************** */

export const toolHelper = {
    data: function () {
        return {
            
        }
    },
    //mounted: function (){},
    methods: {
        searchFilter(value, search) {
            if( value != null && search != null && typeof value === 'string' ){
                value  = value.toString().toLowerCase()
                value  = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                search = search.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLowerCase()
            }
            return value != null && search != null && typeof value === 'string' && value.indexOf(search) !== -1
        },
    }
}