<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation" tile flat>
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> Superficie par milieu</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-chip outlined small label color="green" title="Dernière modification">{{locDateFormat.toLocale(lastUpdatedAt, { year: 'numeric', month: 'long', day: 'numeric' })}}</v-chip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <apexchart v-if="chartView.area" width="100%" height=440 type="pie" :options="chartOptions" :series="series"></apexchart>
                <v-alert v-else color="info" class="mt-2" icon="mdi-information-outline">Aucune donnée</v-alert>
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation" tile flat>
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon> {{ chartSpeciestitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn small :text="(chart2Mode !== 'h')"  :outlined="(chart2Mode !== 'h')" 
                    @click="computeSpecieByMode('h')" :color="(chart2Mode == 'h')  ? 'green' : 'grey'" class="mt-1 mr-2" data-title="Hectare">
                    <v-icon>mdi-shape-square-plus</v-icon> H
                </v-btn>
                <v-btn small :text="(chart2Mode !== 'ml')" :outlined="(chart2Mode !== 'ml')" data-title="Mètres linéaires"
                    @click="computeSpecieByMode('ml')" :color="(chart2Mode == 'ml') ? 'green' : 'grey'" class="mt-1">
                    <v-icon>mdi-arrow-left-right</v-icon> M
                </v-btn>&nbsp;
                <v-btn small text >
                    {{ locDateFormat.toLocale(lastUpdatedAt, { year: 'numeric', month: 'long', day: 'numeric' }) }}
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="min-height:440px;">
                <apexchart v-if="chartView.specie" width="100%" height=400 type="bar" :options="chartOptions2" :series="series2" :style="char2Pointer"></apexchart>
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation" tile flat v-if="isAuthorized( 'Dashboard', 'lastsheet' )">
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon>mdi-format-list-checks</v-icon>
                Derniers sites modifiés
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn icon><v-icon>mdi-restore</v-icon></v-btn> -->
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pb-0" two-line>
            <template v-for="(item, index) in items">
                <v-list-item :key="index" class="pb-0" :href="'/#/dashboard/site/' + item.trs_id ">
                    <v-list-item-avatar>
                        <v-chip small :color="totalColor(item.trs_area_specification)">{{ (Math.round(item.trs_area_specification * 100) / 100).toFixed(2) }}</v-chip>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :style="'color:' + item.sta_color + ';'">
                            {{ item.crd_name }} <span class="caption grey--text">({{ item.trs_name }})</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption font-italic">Code: {{ (item.trs_coddt || item.trs_mc2_code) }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span class="caption">{{ locDateFormat.formatLocale(item.trs_updated_at, 'fr') }}</span>
                        <v-chip small outlined label light :color="( item.trs_sta_id == 'GEN01' ) ? getCacheType('SIT', item.trs_typ_id).typ_color : 'grey'">
                            {{ getCacheType('SIT', item.trs_typ_id).typ_name }}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        <v-card-actions style="cursor:pointer;" @click="goRoute('trackingsheet')">
            <v-icon>mdi-eye-settings-outline</v-icon><span class="ml-3">Liste des sites</span>
            <v-spacer></v-spacer>
            <v-chip small color="light-green" text-color="white">{{ paginSite.total }}</v-chip>
        </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation" tile flat v-if="isAuthorized( 'Dashboard', 'lastevent' )">
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title>
                    <v-icon>mdi-information-outline</v-icon>
                    Évènements
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list class="pb-0" two-line>
                <template v-for="(item, index) in events">
                    <v-list-item :key="index" class="pb-0" :href="(item.eve_table_id == 'trs') ? '/#/dashboard/site/' + item.eve_xxx_id : ''">
                        <v-list-item-content>
                            <v-list-item-title :style="'color:' + item.sta_color + ';'">
                                <span class="caption grey--text">{{ item.eve_title }}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="caption font-italic">{{ item.eve_description }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <span class="caption">{{ locDateFormat.formatLocale(item.eve_date_start, 'fr') }}</span>
                            <v-chip small label outlined>{{ typeState[item.eve_typ_id.substring(0,3)][item.eve_typ_id].typ_name }}</v-chip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="'D-' + index"></v-divider>
                </template>
            </v-list>
            <v-card-actions>
                <span class="ml-3">-</span>
                <v-spacer></v-spacer>
                <v-chip small color="light-green" text-color="white">{{ paginEvent.total }}</v-chip>
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation" tile flat >
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title>
                    <v-icon>mdi-chart-bar</v-icon>
                    Conventions à renouveler
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!--  <v-btn icon><v-icon>mdi-refresh</v-icon></v-btn> -->
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="min-height:440px;">
                <apexchart v-if="chartView.spec" width="100%" type="bar" :options="chartOptions3" :series="series3"></apexchart>
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation" tile flat >
            <v-toolbar flat color="#f6f8f8" prominent height="50px">
                <v-toolbar-title>
                    <v-icon>mdi-chart-bar</v-icon>
                    Surfaces à renouveler
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <!--  <v-btn icon><v-icon>mdi-refresh</v-icon></v-btn> -->
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="min-height:440px;">
                <apexchart v-if="chartView.areaRenew" width="100%" type="bar" :options="chartOptions4" :series="series4"></apexchart>
            </v-card-text>
        </v-card>
    </v-col>
</v-row>

<v-layout row justify-center>
    <v-dialog v-model="dialogsite" hide-overlay persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark>
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Sites: </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="dialogsite = false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>       
        <v-card-text>
            <v-textarea solo label="Informations utiles" hint="Ajouter modifier des informations"></v-textarea>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-layout>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import VueApexCharts from 'vue-apexcharts'
import { cacheGetters } from "../store/cache.module"
import { trsObj }  from '../mixins/trs_obj.js'
import apexLocale  from '@/plugins/locales'
import { usrGetters }   from "../store/user.module"

export default {
    name: 'dashboard',
    props: ['filterView'],
    mixins: [ trsObj ],
    data () {
        return {
            isAuthorized: usrGetters.getScopeRight,
            uiParams: {
                cardElevation: 'elevation-2'
            },
            saveProgress: false,
            dialog: false,
            dialogsite: false,
            paginSite:  { total: 0 },
            paginEvent: { total: 0 },
            locDateFormat: dateLocale, // Format de date
            modalEvent : false,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 0, eve_xxx_id  : '' },
            items: [],
            events: [],
            lastUpdatedAt: '',
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            chartView: { area: false, specie: false, spec: false, areaRenew: false },
            chartSpeciestitle: 'Groupes d\'espèces',
            series: [],
            chartOptions: {
                chart: {
                    toolbar: { show: true, tools: { download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true } },
                    locales: apexLocale.locales, defaultLocale: "fr"
                },
                labels: [],
                responsive: [{
                    breakpoint: 480,
                    options: { chart: { width: 200 }, legend: { position: 'bottom' } }
                }],
                plotOptions: {
                    pie: { dataLabels: { offset: 0, minAngleToShowLabel: 0 }, }
                }
            },
            series2: [],
            chartOptions2: {
                chart: {
                    zoom: { enabled: true },
                    toolbar: { export: { csv: { filename: 'groupe_espece', columnDelimiter: ';' } } },
                    locales: apexLocale.locales, defaultLocale: "fr", //type: 'bar',
                    events: { },
                },
                colors:['#4CAF50', '#FF5722', '#2196F3', '#000'],
                dataLabels: { enabled: false },
                xaxis: { categories: [],  }, //tickPlacement: 'on' type : 'numeric',
                yaxis: [{
                    axisTicks: { show: true, },
                    axisBorder: { show: true, color: '#008FFB' },
                    title: { text: 'Surface (hectare)', }, 
                    tooltip: { enabled: true },
                    //labels: { formatter: () => { return 'rer' }, }
                }],
            },
            chart2Data: {},
            chart2Mode: 'h',
            char2Pointer: 'cursor: auto;',
            series3: [
                //{ name: 'Cahier des charges', type: 'column', data: [] }, 
                //{ name: '-', type: 'line', data: [] }
            ],
            chartOptions3: {
                chart: { 
                    toolbar: { export: { csv: { filename: 'Cahier_des_charges', columnDelimiter: ';' } } },
                    locales: apexLocale.locales, defaultLocale: "fr" 
                },
                stroke: { curve: 'smooth' }, //width: [0, 4],
                //labels: [],
                xaxis: { type: 'datetime' },
                yaxis: [ { title: { text: '', }, }, ],
                dataLabels: { enabled: true, style: { fontSize: '10px', colors: ['#000'] }
                },
            },
            series4: [],
            chartOptions4: {
                chart: { 
                    toolbar: { export: { csv: { filename: 'Surfaces_a_renouveler', columnDelimiter: ';' } } },
                    locales: apexLocale.locales, defaultLocale: "fr" 
                },
                colors: ['#FF9800', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
                plotOptions: { bar: { horizontal: false, dataLabels: { position: 'top', }, } },
                dataLabels: { enabled: true, offsetX: -6, style: { fontSize: '10px', colors: ['#000'] } },
                stroke: { show: true, colors: ['#fff'] },
                xaxis: { type: 'datetime', categories: [], },
            },

        }
    },
    watch: {
        filterView: function(){
            if( !this.filterView ){
                this.getSites()
                this.getSpeciesGroup()
                this.getSpecificationArea()
                this.getSpecificationRenew()
                this.getConventionRenew()
            }
        }
    },
    mounted:function (){
        this.fillData()
        this.getSites()
        this.getEvent()
        this.getSpeciesGroup()
        this.getSpecificationRenew()
        this.getSpecificationArea()
        this.getConventionRenew()
    },
    methods:{
        getSites(){
            this.getTrackingSheetIndex(1, 3, '&order_by=trs_updated_at&order_direction=desc').then( () => {
                this.items = this.listTrackingSheet
                if( this.items[0] ){
                    this.lastUpdatedAt = this.items[0].trs_updated_at
                }
                if( this.metaTrackingSheet ){
                    this.paginSite = Object.assign({}, this.paginSite, this.metaTrackingSheet.pagination)
                }
            })
        },
        getEvent(){
            this.$http.get( '/events/?page=1&per_page=3&order_by=eve_id&order_direction=desc' ).then( (response) => {
                this.events = response.data.data
                if( response.data.meta ){
                    this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
                }
            })
        },
        getSpeciesGroup(){
            let filter    = cacheGetters.getFilter()
            let labelType = 'spg_id'
            this.chartSpeciestitle = 'Groupes d\'espèces'
            if( Array.isArray( filter.csp_spe_id ) && filter.csp_spe_id.length > 0 ){
                labelType = 'csp_spe_id'
                this.chartSpeciestitle = 'Espèces'
            }
            let searchCriteria = this.getTrackingSheetFilter()
            searchCriteria     += '&group_by=csp_spe_id'
            this.$http.get( '/compensationspecies/target/' + searchCriteria).then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.chart2Data = {}
                    response.data.data.forEach(element => {
                        element.spg_name = ( element.spg_name == undefined ) ? 'Inconnu' : element.spg_name
                        element.label = ( labelType == 'csp_spe_id' ) ? element.spe_name : element.spg_name
                        if( this.chart2Data[ element[labelType] ] == undefined ){
                            this.chart2Data[ element[labelType] ] = element
                        } else {
                            this.chart2Data[ element[labelType] ].label = element.spg_name
                            this.chart2Data[ element[labelType] ].csp_value_valid_sum_linear += element.csp_value_valid_sum_linear
                            this.chart2Data[ element[labelType] ].csp_value_valid_sum_area   += element.csp_value_valid_sum_area
                            this.chart2Data[ element[labelType] ].spe_area_due               += element.spe_area_due
                            this.chart2Data[ element[labelType] ].spe_linear_due             += element.spe_linear_due
                        }
                    })
                    this.computeSpecieByMode('h')
                }
                //this.chartView.specie = true
            })
        },
        computeSpecieByMode( mode, range ){
            range           = range || false
            this.chart2Mode = mode
            var series2     = [
                { name: 'Validé',              type: 'column', data: [] },
                //{ name: 'En cours d instruction',type: 'column', data: [] },
                //{ name: 'En projet',             type: 'column', data: [] },
                { name: 'objectif selon l\'échelle géographique concernée',              type: 'column',   data: [] },
            ]
            var chartOptions2 = JSON.parse( JSON.stringify( this.chartOptions2 ) )
            chartOptions2.chart.events.click = (event, chartContext, config) => {
                if( !range && config.dataPointIndex ){
                    this.computeSpecieByMode( this.chart2Mode, config.dataPointIndex )
                    this.char2Pointer = 'cursor: zoom-out;'
                } else if( config.dataPointIndex ){
                    this.computeSpecieByMode( this.chart2Mode, false )
                    this.char2Pointer = 'cursor: auto;'
                }
            }
            chartOptions2.chart.events.dataPointMouseEnter = (event, chartContext, config) => {
                if( !range && config.dataPointIndex ){
                    event.originalTarget.style.cursor = "zoom-in"
                }
            }

            chartOptions2.xaxis.categories = []
            chartOptions2.yaxis[0].labels  = { 
                show: true, align: 'right', //formatter: function (value) { return Math.round(value); } 
            }
            chartOptions2.yaxis[0].title.text = (mode == 'h') ? 'Surface (hectare)' : 'Surface (mètres linéaires)'
            let dataIndex = -1//Object.keys(this.chart2Data).length
            for( let prop in this.chart2Data) {
                dataIndex ++
                if( range !== false && !( dataIndex >= (range - 2)  && dataIndex <= (range + 2)  ) ){
                    continue
                }
                let element = this.chart2Data[prop]
                element.spg_name = ( element.spg_name == undefined ) ? 'Inconnu' : element.spg_name
                if(mode == 'h'){
                    series2[0].data.push( (Math.round(element.csp_value_valid_sum_area * 100) / 100).toFixed(2) )
                    series2[1].data.push( (Math.round(element.spe_area_due * 100) / 100).toFixed(2) )
                } else {
                    series2[0].data.push( Math.round(element.csp_value_valid_sum_linear) )
                    series2[1].data.push( Math.round(element.spe_linear_due) )
                }
                chartOptions2.xaxis.categories.push(element.label)
            }
            this.series2          = series2
            this.chartOptions2    = chartOptions2
            this.chartView.specie = true
        },
        getSpecificationEnv(){
            var searchCriteria = '?per_page=false'
            this.series3[0].data = []
            this.series3[1].data = []
            this.chartOptions3.labels = []
            this.$http.get( '/specification/byenvironment/' + searchCriteria).then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        this.series3[0].data.push( element.spf_count )
                        this.series3[1].data.push( element.spf_count )
                        this.chartOptions3.labels.push( element.spf_year )
                    })

                }
                this.chartView.specie = true
            })
        },
        getSpecificationRenew(){
            let searchCriteria = this.getTrackingSheetFilter()
            //var chartOptions3 = JSON.parse( JSON.stringify( this.chartOptions3 ) )
            var chartOptions4 = JSON.parse( JSON.stringify( this.chartOptions4 ) )
            chartOptions4.xaxis.categories = []
            //chartOptions3.chart.events = {}
            //chartOptions3.labels = []
            var series3 = []
            var series4 = []
            series4 = [ { name: 'Surfaces', data: [] } ]
            series3     = [
                { name: 'Cahier des charges', type: 'column', data: [] }, 
                //{ name: 'Détails', type: 'line', data: [] }
            ]

            //chartOptions3.tooltip = {
            //    x: {
            //        formatter: function (val) {
            //            var date = new Date(val);
            //            return "Année: " + date.getFullYear()
            //        }
            //    }
            //}
            chartOptions4.tooltip = {
                x: { formatter: function (val) { var date = new Date(val); return "Année: " + date.getFullYear() } }
            }

            this.chartOptions4.series4 = [ { name: 'Surfaces', data: [] } ],
            this.chartOptions4.xaxis.categories = []
            this.$http.get( '/specification/torenew/' + searchCriteria).then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        series3[0].data.push( element.spf_count )
                        //series3[1].data.push( element.spf_count )
                        //chartOptions3.labels.push( element.spf_year )

                        series4[0].data.push( (Math.round(element.spf_area_total * 100) / 100).toFixed(2) )
                        chartOptions4.xaxis.categories.push(element.spf_year)
                    })
                }
                this.series3          = series3
                //this.chartOptions3    = chartOptions3
                this.series4          = series4
                this.chartOptions4    = chartOptions4
                //this.chartView.spec   = true
                this.chartView.areaRenew = true
            })
        },
        getConventionRenew(){
            let searchCriteria = this.getTrackingSheetFilter()
            var chartOptions3 = JSON.parse( JSON.stringify( this.chartOptions3 ) )
            chartOptions3.labels = []
            var series3 = []
            series3     = [
                { name: 'Conventions', type: 'column', data: [] }, 
            ]

            chartOptions3.tooltip = {
                x: {
                    formatter: function (val) {
                        var date = new Date(val);
                        return "Année: " + date.getFullYear()
                    }
                }
            }
            this.chartOptions4.series4 = [ { name: 'Surfaces', data: [] } ],
            this.chartOptions4.xaxis.categories = []
            this.$http.get( '/trackingconvention/torenew/' + searchCriteria).then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        series3[0].data.push( element.tcv_count )
                        chartOptions3.labels.push( element.tcv_year )
                    })
                }
                this.series3          = series3
                this.chartOptions3    = chartOptions3
                this.chartView.spec   = true
            })
        },
        getSpecificationArea(){
            let searchCriteria = this.getTrackingSheetFilter()
            var series = []
            var chartOptions = JSON.parse( JSON.stringify( this.chartOptions ) )
            chartOptions.labels = []
            chartOptions.colors = []
            let filter    = cacheGetters.getFilter()
            if( filter.csp_spe_id !== undefined && filter.csp_spe_id.length > 0 ){
                this.chartView.area = false
                return false
            }
            this.$http.get( '/specification/areabytype/' + searchCriteria).then( (response) => {
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    response.data.data.forEach(element => {
                        element.spf_area_total = (Math.round(element.spf_area_total * 100) / 100).toFixed(2)
                        series.push( parseFloat(element.spf_area_total) )
                        chartOptions.labels.push( this.typeState['SPM'][element.cdr_typ_id].typ_name )
                        chartOptions.colors.push( this.typeState['SPM'][element.cdr_typ_id].typ_color )
                    })
                }
                this.series         = series
                this.chartOptions   = chartOptions
                this.chartView.area = true
            })
        },
        goRoute(route){
            this.$router.push({ path: '/' + route + '/' })
        },
        openEvent(id, table_id){
            this.eveInfo.eve_id     = id
            this.eveInfo.eve_xxx_id = table_id
            this.switchModal('modalEvent', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){ 
                this.getEvent()
            }
        },
        totalColor( total ){
            if( total <= 50 ){
                return 'red'
            } else if( total <= 80 ){
                return 'warning'
            } else {
                return 'green'
            }
        },
        fillData () {
            this.datacollection = {
                labels: [this.getRandomInt(), this.getRandomInt()],
                datasets: [
                    {
                        label: 'Donnée 1',
                        backgroundColor: '#f87979',
                        data: [this.getRandomInt(), this.getRandomInt()]
                    }, {
                        label: 'Donnée 2',
                        backgroundColor: '#009688',
                        data: [this.getRandomInt(), this.getRandomInt()]
                    }
                ]
            }
        },
        getRandomInt () {
            return Math.floor(Math.random() * (20 - 5 + 1)) + 5
        },
        getCacheType: cacheGetters.getTypes
    },
    components: {
        'apexchart': VueApexCharts //LineChart
    }
}
</script>

<style>
  .small {
    max-width: 600px;
  }
@import '../assets/global.css';
</style>