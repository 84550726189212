<template>
<div>
    <v-autocomplete ref="autoTeam" v-model="selectCtt[ ctt_typ ]" :items="listCtt[ ctt_typ ]" 
        @input="autoImput" :search-input.sync="search" @change="saveTeam = true"
        item-text="ctt_middlename" item-value="ctt_id" :label="title" style="z-index:2000;"
        :multiple="multiSelect" prepend-inner-icon="mdi-account" chips small-chips clearable hide-details hide-selected >
        <template v-slot:no-data>
            <v-list-item @click="openCreateContact()">
                <v-list-item-title>
                <v-icon color="red">mdi-alert-circle-outline</v-icon>
                &nbsp;<span class="text-capitalize font-weight-bold">[ {{search}} ] </span>introuvable, créer ?
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-autocomplete>
    <!-- Création d'un contact -->
    <v-dialog v-model="modalContact" max-width="900" style="z-index: 1600;">
        <create-contact v-if="modalContact" :switchModal="switchModal" :prefill="{ name: search }" :addToTeam="addToTeam"></create-contact>
    </v-dialog>
</div>
</template>

<script>
import creatContact  from '../components/Edit_contact.vue'

export default {
    name: 'autocomplete_contact',
    props: ['objTrackingSheet', 'ctt_typ', 'title', 'fields'], 
    data: function () {
        return {
            loading     : { getVersion: false, getContact: false },
            search      : null,
            selectCtt   : {},
            listCtt     : {},
            saveContact : false,
            modalContact: false,
            multiSelect   : true,
        }
    },
    watch: {
        search (val) {
            if( this.loading.getContact ){
                clearTimeout(this.loading.getContact)
            }
            if( val && val.length > 1 ){
                this.loading.getContact = setTimeout( () => {
                    let $criteria = encodeURI('?per_page=false&ctt_middlename=%' + val + '%')
                    this.$http.get( '/contacts/' + $criteria ).then( (response) => {
                        this.listCtt[ this.ctt_typ ] = this.objTrackingSheet._data['contacts'][ this.ctt_typ ].concat(response.data.data)
                    })
                }, 800)
            } else {
                return false
            }
        }
    },
    mounted:function (){
        this.multiSelect  = (!this.fields || this.fields.multiSelect == undefined) ? true : this.fields.multiSelect

        this.selectCtt[this.ctt_typ] = []
        this.$set(this.listCtt,   [this.ctt_typ], [])
        this.$set(this.selectCtt, [this.ctt_typ], [])
        Object.assign( this.listCtt[ this.ctt_typ ], this.objTrackingSheet._data['contacts'][ this.ctt_typ ])
        this.listCtt[ this.ctt_typ ].forEach(element => {
            if( this.selectCtt[ this.ctt_typ ].indexOf( element.ctt_id ) === -1 ){
                this.selectCtt[ this.ctt_typ ].push( element.ctt_id )
            }
        })
    },
    methods: {
        autoImput(){
            var listObject = []
            this.listCtt[ this.ctt_typ ].forEach(element => {
                if(this.selectCtt[ this.ctt_typ ].indexOf( element.ctt_id ) > -1){
                    //this.objTrackingSheet._data['contacts'][ this.ctt_typ ].push( element )
                    element.ctr_ctt_id = element.ctt_id
                    element.ctr_typ_id = this.ctt_typ
                    listObject.push( element )
                }
            })
            this.objTrackingSheet._data['contacts'][ this.ctt_typ ] = []
            Object.assign( this.objTrackingSheet._data['contacts'][ this.ctt_typ ], listObject)
        },
        switchModal(name, mode){
            name    = name || 'modalcontact'
            mode    = mode || 0
            //refresh = refresh || false
            this[name] = mode
        },
        openCreateContact(){
            this.modalContact = true
            this.$refs.autoTeam.blur()
        },
        addToTeam(contact){
            const index = this.selectCtt[ this.ctt_typ ].indexOf( contact.ctt_id )
            if (contact.ctt_id !== 0 && index == -1) {
                this.objTrackingSheet._data['contacts'][ this.ctt_typ ].push(contact)
                this.listCtt[ this.ctt_typ ].push( contact )
                this.selectCtt[this.ctt_typ].push( contact.ctt_id )
                this.saveContact = true
            }
        },
    },
    components: {
        'create-contact': creatContact
    }
}
</script>