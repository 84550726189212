<template>
<v-container fluid class="pa-0 ma-0">
<v-card style="z-index:1000;" light>
<v-app-bar flat color="white">
    <v-toolbar-title><v-icon class="mr-3 " color="success" v-text="'mdi-cogs'" ></v-icon> Filtre général</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text class="pl-0" @click="clearFilter"><v-icon :color="(filterCount) ? 'red' : 'grey'" title="Réinitialiser mon filtre">mdi-refresh</v-icon></v-btn>
    <v-btn text :class="(filterCount) ? 'red--text' : ''" @click="saveFilter" title="Enregistrer le filtre">
        <v-icon :color="(filterCount) ? 'green' : 'grey'">mdi-content-save</v-icon>
    </v-btn>
</v-app-bar>
<v-divider></v-divider>
<v-layout row wrap class="mt-3 pl-5 pr-5 pb-5" style="max-width: 850px">
    <v-flex xs6 :class="classColumn">
        <v-autocomplete dense clearable class="ml-2 mr-2"
            v-model="filter.trs_typ_id" :items="typSit" label="Maitrise foncière" 
            item-text="typ_name" item-value="typ_id" multiple chips  @change="updateFilter('trs_typ_id')" prepend-icon="mdi-account-key">
            <template v-slot:selection="data">
                <v-chip :input-value="data.selected" @click:close="removeChip('trs_typ_id', data.item.typ_id)" 
                        close small class="chip--select-multi" :color="data.item.typ_color" >
                    {{ data.item.typ_name.match(/\b(\w)/g).join('.') }}
                </v-chip>
            </template>
            <template slot="item" slot-scope="data">
                {{ data.item.typ_name }}
            </template>
            <template v-slot:no-data>
                Aucune donnée
            </template>
        </v-autocomplete>
    </v-flex>
    <v-flex xs6 :class="classColumn">
        <auto-multi :title="'Petite région agricole'" ressource="smallagrregion" :listObject.sync="filter.sar_id"
            :listParent.sync="filterSource.sar" :fields="{ index: 'sar_id', text:'sar_name', search:'sar_name', preprendIcon:'mdi-tractor' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs6 :class="classColumn">
        <auto-multi :title="'Département'" ressource="department" :listObject.sync="filter.dep_id"
            :listParent.sync="filterSource.dep" :fields="{ index: 'dep_id', text:'dep_name', search:'dep_name,dep_code', preprendIcon:'mdi-map-search' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <!-- <v-flex xs6 :class="classColumn">
        <v-autocomplete disabled dense clearable class="ml-2 mr-2" prepend-icon="mdi-tag"
            v-model="tag_filter" :items="keyWords" label="Tag / mot clé" 
            multiple chips deletable-chips
            item-text="name" item-value="name" >
            <template slot="item" slot-scope="data">
                {{ data.item.name }}
            </template>
            <template v-slot:no-data>
                Aucune donnée
            </template>
        </v-autocomplete>
    </v-flex> -->
    <v-flex xs6 class="classColumn">
        <v-autocomplete dense clearable class="ml-2 mr-2"
            v-model="filter.tst_typ_id" :items="typProc" label="Procédure" 
            item-text="typ_name" item-value="typ_id" multiple chips  @change="updateFilter('tst_typ_id')" prepend-icon="mdi-folder-table">
            <template v-slot:selection="data">
                <v-chip :input-value="data.selected" @click:close="removeChip('tst_typ_id', data.item.typ_id)" 
                        close small class="chip--select-multi" :color="data.item.typ_color" >
                    {{ data.item.typ_name.match(/\b(\w)/g).join('.') }}
                </v-chip>
            </template>
            <template slot="item" slot-scope="data">
                {{ data.item.typ_name }}
            </template>
            <template v-slot:no-data>
                Aucune donnée
            </template>
        </v-autocomplete>
    </v-flex>
    <v-flex xs6 :class="classColumn">
        <auto-multi :title="'Bassins versants'" ressource="watershed" :listObject.sync="filter.was_id"
            :listParent.sync="filterSource.was" :fields="{ index: 'was_id', text:'was_name', search:'was_name', preprendIcon:'mdi-waves' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs5 :class="classColumn">
        <auto-multi :title="'Groupe espèces'" ressource="speciesgroup" :listObject.sync="filter[spgQueryAnd]"
            :listParent.sync="filterSource.spg" :fields="{ index: 'spg_id', text:'spg_name', search:'spg_name', preprendIcon:'mdi-group' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs1 :class="classColumn + ' pt-4 pr-2'" style="text-align: right;">
        <v-btn icon :color="(spgQueryAnd == 'spg_id') ? 'primary' : 'red'" @click="switchQueryMode(spgQueryAnd)">
            <v-icon title="recherche stricte (ex: poisson et reptiles)">mdi-lock-alert</v-icon>
        </v-btn>
    </v-flex>
    <v-flex xs6 :class="classColumn">
        <auto-multi :title="'Cahier des charges'" :ressource="false" :listObject.sync="filter.cdr_typ_id"
            :listParent.sync="filterSource.cdr" 
            :fields="{ index: 'typ_id', text:'typ_name', search:'typ_name', preprendIcon:'mdi-folder-text' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs5 :class="classColumn">
        <auto-multi v-if="!loading.getItem" :title="'Espèces'" ressource="species" :listObject.sync="filter[speQueryAnd]"
            :listParent.sync="filterSource.spe" :fields="{ index: 'spe_id', text:'spe_name', search:'spe_name', preprendIcon:'mdi-ladybug' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs1 :class="classColumn + ' pt-4 pr-2'" style="text-align: right;">
        <v-btn icon :color="(speQueryAnd == 'csp_spe_id') ? 'primary' : 'red'" @click="switchQueryMode(speQueryAnd)">
            <v-icon title="recherche stricte (ex: loutre et alouette)">mdi-lock-alert</v-icon>
        </v-btn>
    </v-flex>
    <v-flex xs6 :class="classColumn" v-if="isAuthorized( 'TrackingSheet', 'show' )">
        <v-autocomplete dense clearable class="ml-2 mr-2" 
            v-model="filter.trs_sta_id" :items="staSit" label="Statut" 
            item-text="sta_label" item-value="sta_id" multiple chips  @change="updateFilter('trs_sta_id')" prepend-icon="mdi-folder-information-outline">
            <template v-slot:selection="data">
                <v-chip :input-value="data.selected" @click:close="removeChip('trs_sta_id', data.item.sta_id)" 
                        close small class="chip--select-multi" :color="data.item.sta_color" >
                    {{ data.item.sta_label.match(/\b(\w)/g).join('.') }}
                </v-chip>
            </template>
            <template slot="item" slot-scope="data">
                {{ data.item.sta_label }}
            </template>
            <template v-slot:no-data>
                Aucune donnée
            </template>
        </v-autocomplete>
    </v-flex>
    <v-flex xs6 class="classColumn">
        <auto-multi :title="'Recherche (CODDT)'" ressource="trackingsheets" :listObject.sync="filter.trs_id"
            :listParent.sync="filterSource.trs" 
            :fields="{ index: 'trs_id', text:'trs_coddt', search:'trs_coddt,trs_mc2_code', preprendIcon:'mdi-code-equal' }"
            class="ml-2 mr-2">
        </auto-multi>
    </v-flex>
    <v-flex xs12>
        <v-btn rounded color="success" @click="closeWindow()">Appliquer</v-btn>
    </v-flex>
</v-layout>
</v-card>
</v-container>
</template>

<script>
import { cacheData, cacheGetters, cacheMutations, cacheActions } from "@/store/cache.module"
import autoCompMulti    from '@/components/Autocomplete_multi.vue'
import { usrGetters }   from "@/store/user.module"
//import { store, mutations } from "../services/store";

export default {
    name: 'view-filter',
    props: ['filterDiff', 'filterCount', 'filterView'],
    data: function () {
        return {
            classColumn: 'pa-0',
            saveProgress: false,
            loading: { getItem: true },
            typSit: [],
            staSit: [],
            typProc: [],
            menu: false,
            spgQueryAnd: 'spg_id',
            speQueryAnd: 'csp_spe_id',
            filter: {
                trs_sta_id: [],
                trs_typ_id: [],
                tag_id:     [],
                dep_id:     [],
                was_id:     [],
                sar_id:     [],
                csp_spe_id: [],
                dash_csp_spe_id: [],
                spg_id:     [],
                dash_spg_id:[],
                trs_id:     [],
                spf_typ_id: [],
                cdr_typ_id: [],
                tst_typ_id: [],
            },
            filterSource:{
                sta: [],
                typ: [],
                tag: [],
                dep: [],
                was: [],
                sar: [],
                spe: [],
                spg: [],
                trs: [],
                spf: [],
                cdr: [],
                pro: [],
            },
            typSource: [],

            filterTab : [],
            sitTab : [],
            tag_filter: [],
            pra_filter: [],
            keyWords: [{name: 'rivière'}, {name: 'contrat'}, {name: 'secteur'}, {name: 'foret'}, {name: 'oiseaux'}],
            storeFilter: {},
            isAuthorized: usrGetters.getScopeRight
        }
    },
    watch: {
        filterView () {
            this.updateFilter()
        }
    },
    beforeMount: function(){
        this.initFilter()
    },
    mounted:function (){
    },
    methods: {
        getCacheType   : cacheGetters.getTypes,
        getCacheStates : cacheGetters.getStates,
        initFilter(){
            for(var prop in this.filter){
                if( cacheData.filter[prop] === undefined ){
                    cacheData.filter[prop] = this.filter[prop]
                }
            }
            this.filter      = cacheGetters.getFilter()
            //Object.assign( this.filter, cacheGetters.getFilter())
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) ) // todoo init if empty
            this.filterSource.dep = cacheGetters.getDepartment()
            this.filterSource.was = cacheGetters.getWatershed()
            this.filterSource.sar = cacheGetters.getSar()
            this.filterSource.spf = cacheGetters.getTypes( 'SPF' )
            this.filterSource.cdr = cacheGetters.getTypes( 'SPM' ).filter(item => item.typ_id !== 'SPM01') // hide ND
            this.typSit           = this.getCacheType('SIT') //.filter(item => item.typ_id !== 'SIT04') // hide prospection
            this.typProc          = this.getCacheType('TST').filter(item => item.typ_tag == 'PRO') // hide prospection
            this.staSit           = this.getCacheStates('GEN')
            this.filterSource.spe = cacheGetters.getSpecies()
            this.filterSource.spg = cacheGetters.getSpeciesGroup()
            //this.filterSource.trs = cacheGetters.getTrackingSheets()
            // Get species source if we have selection in cache
            if( this.filter.csp_spe_id.length > 0 ){
                this.$http.get( '/species/?spe_id=' + this.filter.csp_spe_id.join(',') ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.filterSource.spe = response.data.data
                        this.loading.getItem = false
                    }
                })
            } else {
                this.loading.getItem   = false
            }
        },
        updateFilter(){
            var total = 0
            var diff  = []
            for( let prop in this.filter ){
                if( Array.isArray( this.filter[prop] ) ){
                    diff = this.storeFilter[prop].filter(x => this.filter[prop].indexOf(x) < 0 )
                    total += diff.length
                    diff = this.filter[prop].filter(x => this.storeFilter[prop].indexOf(x) < 0 )
                    total += diff.length
                }
            }
            this.$emit('update:filterCount', total)
        },
        removeItem (item) {
            let index = this.filter.trs_typ_id.indexOf(item.sit_id)
            if (index >= 0) { 
                this.filter.trs_typ_id.splice(index, 1)
            }
        },
        saveFilter(){
            //cacheMutations.setSpecies( JSON.parse( JSON.stringify( this.filterSource.spe ) ))
            cacheMutations.setFilter( this.filter )
            cacheActions.storeCacheToStorage()
            this.storeFilter = JSON.parse( JSON.stringify( this.filter ) )
        },
        clearFilter(){
            var filter      = cacheGetters.getFiltrerFromStorage()
            for(var prop in filter){
                if( Object.prototype.toString.call( this.filter[prop] ) === '[object Array]' ) {
                    this.filter[prop] = filter[prop].slice( this.filter[prop] )
                } else if( this.filter[prop] !== undefined ){
                    this.filter[prop] = filter[prop]
                }
            }
            this.updateFilter()
        },
        closeWindow(){
            this.$emit('update:filterView', false)
        },
        removeChip (fromFilter, id) {
            const index = this.filter[fromFilter].indexOf(id)
            if (index >= 0) this.filter[fromFilter].splice(index, 1)
        },
        switchQueryMode(key){
            this.filter[key] = []
            if( key == 'spg_id' || key == 'dash_spg_id' ){
                this.spgQueryAnd = ( this.spgQueryAnd == 'spg_id' ) ? 'dash_spg_id' : 'spg_id'
            }
            if( key == 'csp_spe_id' || key == 'dash_csp_spe_id' ){
                this.speQueryAnd = ( this.speQueryAnd == 'csp_spe_id' ) ? 'dash_csp_spe_id' : 'csp_spe_id'
            }
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>