<template>
<v-container fluid class="pa-1">
    <v-data-table :headers="headers" :items="items" :items-per-page="10" 
        class="elevation-1" :item-class="tableRowStyle" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title v-show="(title)">
                    <v-alert class="mb-0" border="left" dense colored-border type="info">{{title}}</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn v-if="isAuthorized( 'Event', 'store' )" outlined rounded small :color="(routeState.view) ? 'grey' : 'green'" @click="openEvent(0)" :disabled="!routeState.edit">
                Ajouter<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`header.eve_typ_id`]="{ header }">
            <v-menu offset-y rounded :close-on-content-click="false" max-height="300" style="z-index:1500;" v-if="typfilter">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :color="(headFilter.eve_typ_id.length) ? 'green' : 'grey'"><v-icon small>mdi-filter</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in getCacheType(typfilter.substring(0, 3))" :key="index" @mousedown.prevent>
                        <v-list-item-action><v-checkbox color="primary" :value="index" @click="updateHeaderFilter('eve_typ_id', item.typ_id)" hide-details></v-checkbox></v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>
                            {{ getCacheType(typfilter.substring(0, 3), item.typ_id).typ_name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu> 
            {{ header.text }}
        </template>

        <template v-slot:[`item.eve_typ_id`]="{ item }">
            <v-chip label outlined>{{ typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.doc_ids`]="{ item }">
            <v-badge :color="(item.doc_ids) ? 'green' : 'grey'" overlap offset-x="1" bordered :content="(item.doc_ids) ? item.doc_ids : '0'">
                <v-icon right>mdi-paperclip</v-icon>
            </v-badge>
        </template>
        <template v-slot:[`item.eve_sta_id`]="{ item }">
            <v-chip label outlined color="green">{{ stateState['GEN'][item.eve_sta_id].sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.eve_date_start`]="{ item }">
            <v-chip label outlined>{{ locDateFormat.toLocale( item.eve_date_start ) }}</v-chip>
        </template>
        <template v-slot:[`item.eve_title`]="{ item }">
            <span class="d-inline-block text-truncate" style="max-width: 300px;">
                {{ (item.eve_title && item.eve_title.length > 2) ? item.eve_title : item.eve_description }}
            </span>
        </template>
        <template v-slot:[`item.eve_resolve_percent`]="{ item }">
            <div v-if="item.eve_typ_id_rec == 'REC01'">
                <v-simple-checkbox v-if="!(typfilter == 'CTR')" :value="(item.eve_resolve_percent) ? true : false" disabled></v-simple-checkbox>
                <span v-else>{{ item.eve_resolve_percent }} %</span>
            </div>
            <div v-else>
                <v-chip title="Evènement virtuel" label outlined color="orange"><v-icon>mdi-calendar-multiselect</v-icon></v-chip>
            </div>
        </template>
        <template v-slot:[`item.eve_id`]="{ item }">
            <v-chip v-if="isAuthorized( 'Event', 'update' )" @click="virutalEvent = item; openEvent(item)" color="grey" label outlined><v-icon>mdi-eye</v-icon></v-chip>
            <v-chip v-else @click="openEvent(item)" color="grey" label outlined><v-icon>mdi-eye</v-icon></v-chip>
        </template>
        <template v-slot:[`item.eve_custom_fields`]="{ item }">
            <v-chip color="grey" label outlined>{{ (item.eve_custom_fields && item.eve_custom_fields.tcv_code) ? item.eve_custom_fields.tcv_code : '' }}</v-chip>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !loading.getEvent )" color="info" icon="mdi-information-outline">
            Aucun événement
        </v-alert>
        </template>
    </v-data-table>
    <v-dialog v-model="modalEvent" max-width="900" style="z-index:1056;">
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Évènement</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="pa-2">
            <edit-event class="elevation-0" 
                v-if="modalEvent" :switchModal="switchModal" :virutalEvent="virutalEvent"
                :xxxId="eveInfo.eve_xxx_id" 
                :eveId="eveInfo.eve_id" :eveTable="(table_id) || 'trs'" :eveType="(typfilter) ? typfilter.substring(0, 3) : typfilter"
                :sheetType="(sheetType) ? sheetType : false">
            </edit-event>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import editEvent  from '../components/Edit_event.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"

export default {
    name: 'tab_event',
    props: ['table_id', 'eve_xxx_id', 'title', 'typfilter', 'routeState', 'sheetType'],
    data: function () {
        return {
            loading: { uploadBtn: false, rebuildBtn: false, getEvent: false },         // Enregistrement en cours...
            saveProgress: false,         // Enregistrement en cours...
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            typeState: cacheGetters.getTypes(),
            stateState: cacheGetters.getStates(),
            headers: [
            { text: 'Type',     align: 'left', value: 'eve_typ_id'},
            { text: 'Titre',    align: 'left', value: 'eve_title'},
            { text: 'Date',     align: 'left', value: 'eve_date_start'},
            { text: 'Fichiers', value: 'doc_ids' },
            //{ text: 'Statut',   value: 'eve_sta_id' },
            { text: 'Réalisé', value: 'eve_resolve_percent' },
            { text: 'Consulter',   value: 'eve_id' }
            ],
            sortBy: 'eve_date_start',
            sortDesc: false,
            headFilter: { eve_typ_id: [] },
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            prv_id: 0,
            eve_id: 0,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: '', eve_xxx_id  : 0 },
            modalPagin : true,
            virutalEvent: {},
            xxx_id: 0,
            isAuthorized: usrGetters.getScopeRight
        }
    },
    mounted: function (){
        if( this.typfilter == 'CTR' && this.headers.length <= 7 ){
            this.headers.unshift({ text: (this.sheetType == 'SIT02') ? 'Plan' : 'Convention',   value: 'eve_custom_fields' })
        }
        this.getEvent()
    },
    watch: {
        typfilter: function(){
            if( this.typfilter == 'CTR' && this.headers.length <= 7 ){
                this.headers.unshift({ text: (this.sheetType == 'SIT02') ? 'Plan' : 'Convention',   value: 'eve_custom_fields' })
            } else if( this.headers.length > 7 ){
                this.headers.shift()
            }
            this.getEvent()
        }
    },
    methods: {
        async getEvent(){
            this.items = []
            if( Object.prototype.toString.call( this.table_id ) === '[object Array]' ){
                for (const tableId of this.table_id) {
                    let fieldId = (tableId == 'trs') ? '&eve_xxx_id=' + this.eve_xxx_id : '&' + tableId + '_trs_id=' + this.eve_xxx_id
                    await this.getEvents(tableId, fieldId)
                }
            } else {
                await this.getEvents(this.table_id, '&eve_xxx_id=' + this.eve_xxx_id)
            }
            this.loading.getEvent = false
        },
        getEvents(tableId, fieldId){
            return new Promise( (resolve, reject) => {
                this.loading.getDocument = true
                var searchCriteria = '?per_page=false' //page='+ this.pagination.page +'&
                //searchCriteria    += '&eve_xxx_id=' +  this.eve_xxx_id + '&eve_table_id=' + this.table_id
                searchCriteria    += '&eve_table_id=' + tableId + fieldId + '&extended=bytrackingsheet,withattacheddocument'
                searchCriteria    += ( this.typfilter ) ? '&eve_typ_id=%' + this.typfilter + '%' : ''
                this.$http.get( '/events/' + searchCriteria ).then( (response) => {
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.items.push(...response.data.data)
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        openEvent(item){
            this.eveInfo.eve_xxx_id = (!item.eve_xxx_id) ? this.eveInfo.eve_xxx_id : item.eve_xxx_id
            this.eveInfo.eve_xxx_id = (!this.eveInfo.eve_xxx_id) ? this.eve_xxx_id : this.eveInfo.eve_xxx_id
            this.eveInfo.eve_id = (item.eve_id) ? item.eve_id : 0
            this.switchModal('modalEvent', 1)
        },
        openAssocModal(id){
            if( id !== undefined && id > 0 ){
                this.switchModal('modalEvent', 1)
            }
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || true
            this[name] = mode
            if( mode == 0 ){
                this.ctp_id = 0
            }
            if( refresh ){ 
                this.getEvent()
            }
        },
        updateHeaderFilter(filter, value){
            let options = ''
            let index   = this.headFilter[filter].indexOf( value )
            if( index > -1 ){
                this.headFilter[filter].splice(index, 1)
            } else {
                this.headFilter[filter].push(value)
            }
            options = this.headFilter[filter].join(',')
            if( options.length > 1 ){
                this.getEvent( '&eve_typ_id=' + options )
            } else {
                this.getEvent()
            }
        },
        tableRowStyle(item) {
            if (item.eve_typ_id_rec !== 'REC01') {
                return "virtual-event"; //can also return multiple classes e.g ["orange","disabled"]
            } 
        },
        getCacheType   : cacheGetters.getTypes,
    },
    components: {
        'edit-event'   : editEvent
    }
}
</script>

<style lang="css">
.virtual-event{
    opacity: 0.5;
}
</style>