var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"grey lighten-3",staticStyle:{"z-index":"1054"},attrs:{"clipped":"","stateless":_vm.drawerStateless,"fixed":"","temporary":"","right":"","width":_vm.rightDrawerWidth,"hide-overlay":_vm.hideOverlay},model:{value:(_vm.objTrackingSheet.trs_id),callback:function ($$v) {_vm.$set(_vm.objTrackingSheet, "trs_id", $$v)},expression:"objTrackingSheet.trs_id"}},[_c('v-app-bar',{staticClass:"elevation-1",staticStyle:{"z-index":"1054"},attrs:{"dark":"","color":(_vm.element.state == 'modifier') ? 'red darken-4' : 'primary',"height":"56px","absolute":""}},[_c('v-app-bar-title',[_vm._v(_vm._s(_vm.site_name))]),_c('v-btn',{attrs:{"icon":"","small":"","href":_vm.link.consult}},[_c('v-icon',[_vm._v(_vm._s((_vm.element.state == 'apercu') ? 'mdi-close' : 'mdi-arrow-left'))])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":600,"offset-y":"","z-index":"1100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"right":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"slot":"activator","icon":""},slot:"activator"},Object.assign({}, tooltip, menu)),[_c('v-badge',{staticClass:"mt-1",attrs:{"color":"red","content":_vm.bookmarkCount,"value":_vm.bookmarkCount}},[_c('v-icon',[_vm._v("mdi-card-bulleted-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Voir les sites en favoris")])])]}}]),model:{value:(_vm.siteMenu),callback:function ($$v) {_vm.siteMenu=$$v},expression:"siteMenu"}},[_c('view-opencard',{staticClass:"mt-2",staticStyle:{"z-index":"900 !important"},attrs:{"tabsheetCount":_vm.bookmarkCount,"menuState":_vm.openCardState}})],1),_c('v-spacer'),((_vm.element.state == 'modifier') && _vm.isAuthorized( 'TrackingSheet', 'update' ))?_c('v-btn',{staticClass:"white--text",attrs:{"rounded":"","depressed":"","color":"grey","href":_vm.link.base + '/site/' + _vm.objTrackingSheet.trs_id + '/consulter'}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Retour")]),_c('v-icon',{staticClass:"hidden-md-and-up",attrs:{"right":""}},[_vm._v("mdi-arrow-left")])],1):_vm._e(),((_vm.element.state == 'consulter' || _vm.element.state == 'apercu') && _vm.isAuthorized( 'TrackingSheet', 'update' ))?_c('v-btn',{staticClass:"white--text",attrs:{"rounded":"","depressed":"","color":"green","href":_vm.link.base + '/site/' + _vm.objTrackingSheet.trs_id + '/modifier'}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Modifier")]),_c('v-icon',{staticClass:"hidden-md-and-up",attrs:{"right":""}},[_vm._v("mdi-pencil-plus")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"x-small":"","fab":"","depressed":"","color":(_vm.bookmark['b-' + _vm.objTrackingSheet.trs_id]) ? 'amber darken-1' : 'grey darken-1'},on:{"click":function($event){return _vm.addToBookmark()}}},on),[_c('v-icon',[_vm._v("mdi-star")])],1)]}}])},[_c('span',[_vm._v("Ajout favoris")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"x-small":"","fab":"","depressed":"","color":"light-green darken-1"},on:{"click":function($event){return _vm.leftDrawerMaximize()}}},on),[_c('v-icon',[_vm._v("mdi-window-maximize")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.labels.windowSize))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"x-small":"","fab":"","depressed":"","color":"red darken-1"},on:{"click":function($event){return _vm.closeSlider()}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fermer")])])],1),(_vm.openRoute)?_c('router-view',{attrs:{"name":"content","infTrackingSheet":_vm.objTrackingSheet,"routeState":_vm.element}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }