<template>
<v-card color="grey lighten-4" flat>
    <v-toolbar color="green" dark class="elevation-2" height="50px">
        <v-icon class="mr-3">mdi-chevron-down</v-icon>
        <v-toolbar-title>{{ cardTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="" @click.native="switchModal('trackingplot', 0, 0)"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="pt-2">
        <v-form ref="userForm" lazy-validation>
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Commune'" ressource="towns" :listObject.sync="autoComplete.trp_tow_id"
                    :listParent.sync="autoCompleteSource.tow" :fields="{ index: 'tow_id', text:'tow_name,tow_zip', search:'tow_name', multiSelect:false }"
                    class="ml-2 mr-2" :disabled="!editMode">
                </auto-multi>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-select 
                    :items="typeItem" v-model="objItem.trp_typ_id" label="Type de parcelle"
                    item-text="typ_name" item-value="typ_id" :disabled="!editMode">
                </v-select>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Section" v-model="objItem.trp_section" required :disabled="!editMode"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="N° Parcelle" v-model="objItem.trp_plot_id" required :disabled="!editMode"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Feuille" v-model="objItem.trp_sheet" required :disabled="!editMode"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Surface" v-model="objItem.trp_area" required :disabled="!editMode"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <v-text-field label="N° Ilot" v-model="objItem.trp_ilot_no" required :disabled="!editMode"></v-text-field>
            </v-flex>
            <v-flex xs6 class="pa-2">
                <auto-multi v-if="!loading.getItem" :title="'Parcelle origine'" ressource="trackingplot" :listObject.sync="autoComplete.plot"
                    :listParent.sync="autoCompleteSource.plot" :fields="{ 
                        index: 'trp_id', text:'trp_plot_id,trp_section,trp_sheet', search:'trp_plot_id', multiSelect:false, prefetch: true
                    }"
                    class="ml-2 mr-2" :disabled="!editMode">
                </auto-multi>
            </v-flex>
            </v-layout>
        </v-form>
    </v-card-text>
    <v-card-actions style="background-color:white;" v-if="isAuthorized( 'TrackingSheet', 'destroy' )">
        <v-btn color="error" v-if="!loading.getItem && itemId > 0" :loading="saveProgress" @click.native="deleteItem()" :disabled="!validForm">
            <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" v-if="!loading.getItem" :loading="saveProgress" @click.native="saveItem()" :disabled="!validForm">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('trackingplot', 0, 0)">Retour</v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
import dateLocale from '../services/dateLocale';
import autoCompMulti    from './Autocomplete_multi.vue'
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"
import { trackingsheetUpdateService } from '@/rxjsServices';

export default {
    name: 'edit_tracking_plot',
    props: ['switchModal', 'objTrackingSheet', 'itemId'],
    data: function () {
        return {
            cardTitle: 'Parcelle',
            locDateFormat: dateLocale, // Format de date
            saveProgress: false,         // Enregistrement en cours...
            loading: { getItem: true },
            typeItem: cacheGetters.getTypes('PLO'),
            objItem: { 
                trp_id: this.itemId, trp_trs_id: this.objTrackingSheet.trs_id, trp_typ_id: 'PLO01', trp_tow_id: null,
                trp_section: '', trp_plot_id: '', trp_sheet: null, trp_area: 0, trp_ilot_no: '', trp_mc2_plot: '', trp_lat: null, trp_lng: null,
            },
            nameRules: [
                v => !!v || 'Ce champ est obligatoire',
                v => v.length <= 250 || 'Le champ doit avoir moins de 255 charactères'
            ],
            autoComplete: {
                trp_tow_id: null,
                plot: []
            },
            autoCompleteSource:{
                tow: [],
                plot: []
            },
            isAuthorized: usrGetters.getScopeRight,
            editMode: true
        }
    },
    computed: {
        validForm() {
            if( this.objItem.trp_trs_id > 0 && this.autoComplete.trp_tow_id > 0 && this.objItem.trp_typ_id.length > 3 ){
                return true
            } else {
                return false
            }
        },
    },
    beforeMount: function(){
        this.editMode = this.isAuthorized( 'TrackingSheet', 'update' )
    },
    mounted:function (){
        if( this.itemId > 0){
            this.getItem()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        getItem(){
            return new Promise( (resolve, reject) => {
                this.items = []
                this.loading.getItem = true
                this.$http.get( '/trackingplot/' + this.itemId + '/?include=town' ).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objItem[prop] = response.data.data[prop]['data']
                        } else {
                            this.objItem[prop] = response.data.data[prop]
                        }
                    }
                    if( this.objItem.town ){
                        this.autoCompleteSource.tow.push(this.objItem.town)
                        this.autoComplete.trp_tow_id = this.objItem.trp_tow_id
                    }
                    this.loading.getItem = false
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveItem() {
            this.objItem.trp_tow_id = this.autoComplete.trp_tow_id || null
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.itemId > 0 ){
                this.$http.put( '/trackingplot/' + this.itemId, this.objItem ).then( () => {
                    this.$http.get( '/synchro/trackingarea/' + this.objTrackingSheet.trs_id ).then( () => {
                        trackingsheetUpdateService.sendTrackingsheet('update')
                    })
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('trackingplot', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error })
                    this.switchModal('trackingplot', 0, 1)
                })
            } else {
                this.$http.post( '/trackingplot', this.objItem ).then( () => {
                    this.$http.get( '/synchro/trackingarea/' + this.objTrackingSheet.trs_id ).then( () => {
                        trackingsheetUpdateService.sendTrackingsheet('update')
                    })
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche enregistré' })
                    this.switchModal('trackingplot', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ 
                        color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de l\'enregistrement !\n' + error 
                    })
                    this.switchModal('trackingplot', 0, 0)
                });
            }
        },
        deleteItem(){
            if( this.itemId > 0 ){
                this.$http.delete( '/trackingplot/' + this.itemId ).then( () => {
                    this.$root.$toast({ color: 'success', top:false, bottom:true, right:false, left:true, text:'Fiche supprimée' })
                    this.switchModal('trackingplot', 0, 0, true)
                }).catch( (error) => {
                    this.$root.$toast({ color: 'error', top:false, bottom:true, right:false, left:true, text:'Erreur lors de la suppression !\n' + error })
                    this.switchModal('trackingplot', 0, 1)
                })
            }
        }
    },
    components: {
        'auto-multi': autoCompMulti
    }
}
</script>